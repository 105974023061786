import { Button, Box, Typography } from "@material-ui/core";
import { updateSet } from "./catalogUtils";


const CatalogToggleButtonFilterCollection = ({filters, resetActiveButton, title, filterTypeSelection, setInput, className = "greenButton" , activeButtonSet, isMobile}) => {
    const ClearButton = () => (
        <Button id={isMobile ? "clearButtonMobile" : "clearButtonDesktop"}
            onClick={
                () => {
                    setInput([])
                    activeButtonSet.clear();
                }
            }>  
            Clear All
        </Button>
    )
    return (
        <>
        <Box className="typeHeader">
            <Typography className="typeSubHeaderTitle">{title}</Typography>
            {isMobile ? null : <ClearButton /> }
        </Box>
            <Box className={isMobile ? "filterButtonsMobile" : "filterButtonsDesktop"}>
            {Array.from(filters.keys()).map(key =>
                <Button
                    className={activeButtonSet.has(key) ? "active " + className : className}
                    value={key}
                    name={key}
                    key={key}
                    onClick={
                        () => {
                            updateSet(activeButtonSet, key)
                            filters.get(key).forEach(val => updateSet(filterTypeSelection, val))
                            setInput(Array.from(filterTypeSelection))
                        }
                    }
                >
                    {key}
                </Button>
            )}
            {isMobile ? <ClearButton /> : null}
            </Box>
    </>
    )
}

export default CatalogToggleButtonFilterCollection;