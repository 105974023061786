import { useState } from 'react';

import RenderContactForm, { ContactFormMetadataBuilder } from "./RenderContactForm";

import { Modal } from "@material-ui/core"


function SimpleModal(props) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="contactForm">
                    <button className="largeGreenButton"type="button" onClick={handleOpen}>
                        Learn More
                    </button>
                    <Modal
                        ref={props.ref}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="contact-form-modal-title"
                        aria-describedby="contact-form-modal-description"
                    >
                        {props.children}
                    </Modal>
        </div>
    )
}

async function sendContactForm(first_name, last_name, email, phone, company, fleetsize, zip, comments, terms, age) {
    const crypto = require('crypto');
    try {
        const feedback = { first_name, last_name, email, phone, company, fleetsize, zip, comments }
        const REACT_APP_FEEDBACK_DEV_BACKEND_HOST = "https://3taqm7xcih.execute-api.us-east-1.amazonaws.com/Prod/ingress"
        const REACT_APP_FEEDBACK_PROD_BACKEND_HOST = "https://gs.zappyride.com/ingress"
        const REACT_APP_FEEDBACK_DEV_SERVICE_ID = "f990319d-b5c8-44c8-9d6b-b786e98d6c2c"
        const REACT_APP_FEEDBACK_PROD_SERVICE_ID = ""
        let url = new URL(
            process.env.NODE_ENV === "production" ? REACT_APP_FEEDBACK_PROD_BACKEND_HOST : REACT_APP_FEEDBACK_DEV_BACKEND_HOST
        );
        const payload = Buffer.from(JSON.stringify(feedback)).toString('base64');
        const feedback_info = {
            service: process.env.NODE_ENV === "production" ? REACT_APP_FEEDBACK_PROD_SERVICE_ID : REACT_APP_FEEDBACK_DEV_SERVICE_ID,
            payload: [payload],
            checksum: "-Sha1-" + crypto.createHash("sha1").update(payload, "utf-8").digest("hex")
        }

        window
            .fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(feedback_info)
            })

    } catch (error) {
        console.log('error signing in', error);
    }
}

const ContactFormModal = () => {
    return (
        <SimpleModal>
            <RenderContactForm name="feeback" componentMetadata={
                ContactFormMetadataBuilder()
                .withOnSubmitFunction(
                    ({ usersFirstName, usersLastName, usersEmail, usersPhone, usersCompany, usersFleetSize, usersZip, usersComments, handleChange }) => { sendContactForm(usersFirstName, usersLastName, usersEmail, usersPhone, usersCompany, usersFleetSize, usersZip, usersComments, handleChange) })
                    .build()} 
            />
        </SimpleModal>
    )
}

export default ContactFormModal;

