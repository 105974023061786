
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      backgroundColor: "#fff",
      height: 'fit-content'
    },
  }));

const SimpleModal = ({ children, openButtonName, isOpen, onClick }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(isOpen);

    const handleOpen = () => {
        setOpen(true);
        onClick(true)
    };

    const handleClose = () => {
        setOpen(false);
        onClick(false)
    };

    // const childrenWithProps = React.Children.map(children, child => {
    //     // Checking isValidElement is the safe way and avoids a typescript
    //     // error too.
    //     if (React.isValidElement(child)) {
    //       return React.cloneElement(child, { doSomething });
    //     }
    //     return child;
    //   });

    return (
        <div className="simpleModal">  
            <button className="largeGreenButton" type="button" onClick={handleOpen}>
                {openButtonName}
            </button>
            <Modal
                className={classes.paper}
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {/* {React.cloneElement(children, { handleClose })}                 */}
                {children}
            </Modal>

        </div>
    )
}

export default SimpleModal