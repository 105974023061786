export const HATCHBACK = "Hatchback";
export const SUV = "SUV";
export const PICKUP_TRUCK = "pickup_truck";
export const CARGO_VAN = "cargo_van";
export const MINIVAN = "Minivan";
export const SEDAN = "Sedan";
export const STEP_VAN = "step_van";
export const COUPE = "Coupe";

export type FormFactor =
  | typeof HATCHBACK
  | typeof SUV
  | typeof PICKUP_TRUCK
  | typeof CARGO_VAN
  | typeof MINIVAN
  | typeof SEDAN
  | typeof STEP_VAN
  | typeof COUPE;

export const formFactorsList: Array<FormFactor> = [
  HATCHBACK,
  SUV,
  PICKUP_TRUCK,
  CARGO_VAN,
  MINIVAN,
  SEDAN,
  STEP_VAN,
  COUPE,
];
