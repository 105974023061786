import React from 'react'
import IncentiveOverviewTab from "../../ComponentMetadata/IncentiveOverviewMetadata"
import IncentiveEligibilityTab from "../../ComponentMetadata/IncentiveEligibilityMetadata"
import IncentiveFundingTab from "../../ComponentMetadata/IncentiveFundingMetadata"
import IncentiveChangelogTab from "../../ComponentMetadata/IncentiveChangelogMetadata"
import IncentiveCatalogGrid from "../catalog/IncentiveCatalogGrid"
import { filterValues } from '../catalog/catalogUtils';
import PrintModal from '../modal/PrintModal/PrintModal';
import GM_Header from '../../ComponentMetadata/HeaderMetadata';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import GenericMetadataParser from '../GenericMetadataParser'
import { useGetCommercialIncentiveQuery } from '../../services/unifiedAPIQuery';
import { useParams } from 'react-router';
import { updaters } from '../../slices/IncentivePageNavigationSlice';
import { useDispatch } from 'react-redux';

import IncentiveDetailsMetadata from '../../ComponentMetadata/IncentiveDetailsMetadata'


const IncentiveDetailsPage = () => {
    const { handle } = useParams()
    const { data, error, isLoading, isUninitialized } = useGetCommercialIncentiveQuery(
        { handle: handle },
    )

    let incentive = null
    if (data) {
        incentive = data.incentive
    }

    const incentiveFilterFieldNames = ["Date", "Changes", "Anticipated Future Change", "Next Update"]
    const incentiveFilterFieldNameToValueMapping = ["changelog_date", "changelog_description", "anticipated_changes", "next_scheduled_review"]

    const IncentiveChangeLog = (incentive) => {
        return (
            IncentiveChangelogTab()
                .withComponent(
                    () => {
                        return (
                            <React.Fragment>
                                <React.Fragment>
                                    <IncentiveCatalogGrid
                                        indexes={
                                            filterValues(incentive.changelog, incentiveFilterFieldNames, incentiveFilterFieldNameToValueMapping)
                                        }
                                        idField="changelog_date"
                                        data={incentive.changelog}
                                        containerProps={{ lg: 12, container: true, spacing: 3 }}
                                    />
                                </React.Fragment>
                            </React.Fragment>
                        )
                    }
                )
                .withPageTitle("Incentive details are updated as new information becomes available. This changelog captures those changes.")
                .build()
        )
    }
    const SegmentFunction = (segmentInfo) => {
        let form_factor_mapping = { 'Hatchback': 'Small MPV', 'SUV': 'Small SUV', 'pickup_truck': 'Midsize Pickup','cargo_van' : 'Cargo Van'}
        let segments = []

        if (segmentInfo.includes() === "all") {
            for (const item in form_factor_mapping) {
                segments.push(form_factor_mapping[item])
            }
        } else if (segmentInfo) {
            segmentInfo.forEach(i => {
                if (form_factor_mapping[i]) {
                    segments.push(form_factor_mapping[i])
                }
            })
        }
        return segments.join(', ')
    }

    const DateFunction = (startDate, expirationDate, status) => {
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // red, orange, green
        let colors = ['#ff3831', '#ffa943', '#417c29'];
        let [day, month_word, year, expiration_date, start_date, days_left, deadline_color, expiration_date_slash, start_date_slash] = ['', '', '', '', '', '', '', '', '']

        const formatWesternDate = (day) => {
            let date_array = day.split('-')
            let year_number = date_array.shift()
            date_array.push(year_number)
            return date_array.join("/")
        }

        if (startDate) {
            start_date_slash = formatWesternDate(startDate)
            start_date = new Date(startDate)
            month_word = months[start_date.getMonth()]
            day = start_date.getDate()
            year = start_date.getFullYear()
        }
        if (expirationDate !== '2038-01-01' && expirationDate) {
            expiration_date_slash = formatWesternDate(expirationDate)
            expiration_date = new Date(expirationDate)
            expiration_date.toLocaleString('en-US', { timeZone: 'America/New_York' })
            month_word = months[expiration_date.getMonth()]
            day = expiration_date.getDate()
            year = expiration_date.getFullYear()
        }
        let today = new Date();
        const oneDay = 1000 * 60 * 60 * 24;
        if (expiration_date - today > 0) {
            days_left = Math.round((expiration_date - today) / oneDay)
        } else if (status === 'expired' || status === 'suspended') {
            days_left = 0
        } else {
            days_left = -1
        }

        let complete_date = month_word + ' ' + day + ', ' + year
        if (days_left < 31) {
            deadline_color = colors[0]
        } else if (days_left > 60) {
            deadline_color = colors[2]
        } else {
            deadline_color = colors[1]
        }
        return [complete_date, days_left, deadline_color, start_date_slash, expiration_date_slash]
    }

    const IncentiveOverview = (incentive) => {
        return (
            IncentiveOverviewTab()
                .withIncentiveStatus(incentive.current_program_status)
                .withIncentiveUrl(incentive.incentive_url)
                .withIncentiveName(incentive.incentive_name)
                .withIncentiveDescription(incentive.incentive_description)
                .withIncentiveScope(incentive.incentive_scope_description)
                .withStartDate(incentive.start_date)
                .withExpirationDate(incentive.expiration_date)
                .withDateFunction(DateFunction)
                .withGrantorName(incentive.grantor_name)
                .withGrantorType(incentive.grantor_type)
                .withGrantorInfo(incentive.incentive_program_contact_info)
                .build()
        )
    }
    const IncentiveEligibility = (incentive) => {
        return (
            IncentiveEligibilityTab()
                .withEligibleProjectType(incentive.eligible_project_types)
                .withEligibleProjectDescription(incentive.eligible_project_type_description)
                .withEligibilityAssumptions(incentive.eligibility_assumptions)
                .withEligibleVehicleType(incentive.eligible_vehicle_type)
                .withEligibleVehicleDescription(incentive.eligible_vehicle_type_description)
                .withEligibleVehicleDuties(incentive.eligible_vehicle_duties)
                .withEligibleVehicleWeightClasses(incentive.eligible_vehicle_weight_classes)
                .withEligibleVehicleFuelTypes(incentive.eligible_fuel_types)
                .withEligibleVehicleSegments(incentive.is_eligible_to_specified_form_factors_only)
                .withEligibleOrganizations(incentive.eligible_organizations)
                .isEligibleLease(incentive.is_lease_eligible)
                .withLeaseBeneficiary(incentive.lease_beneficiary)
                .withSegmentFunction(SegmentFunction)
                .build()
        )
    }
    const IncentiveFunding = (incentive) => {
        return (
            IncentiveFundingTab()
                .withTotalFunding(incentive.total_funding)
                .withCurrentFunding(incentive.current_funding)
                .withFundingSituationDescription(incentive.funding_situation_description)
                .withMethodologyDescription(incentive.incentive_calculation_methodology_description)
                .withFundingCapPerProject(incentive.funding_cap_per_project)
                .withFundingCapPerApplicant(incentive.funding_cap_per_applicant)
                .build()
        )
    }
    const ArrowClick = () => {
        const dispatch = useDispatch();
        const ArrowActions = () => {
            dispatch(updaters.currentTab(0))
            window.history.back();
        }
        return <ArrowBackIcon className="arrowBack" onClick={() => ArrowActions()} />
    }
    const ExportClick = (incentive) => {
        const ExportActions = () => {
            const XLSX = require("xlsx");
            let ws = XLSX.utils.json_to_sheet([incentive])
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Incentive");
            XLSX.writeFile(wb, `${incentive.incentive_handle}.xlsx`);
        }
        return (
            <div onClick={() => ExportActions()} className="exportButton">
                <GetAppIcon />
                <p className="exportTitle">Export</p>
            </div>
        )
    }

    const tabItemsFunction = (incentive) => [
        { id: "overview", component: <GenericMetadataParser className="overview_tabs" componentMetadata={IncentiveOverview(incentive)} /> },
        { id: "eligibility", component: <GenericMetadataParser className="eligibility_tabs" componentMetadata={IncentiveEligibility(incentive)} /> },
        { id: "funding", component: <GenericMetadataParser className="funding_tabs" componentMetadata={IncentiveFunding(incentive)} /> },
        { id: "changelog", component: <GenericMetadataParser className="catalog_tabs" componentMetadata={IncentiveChangeLog(incentive)} /> }
    ];

    const headerMeta = GM_Header().build()
    const tabsDisplay =
        (incentive) ?
            <React.Fragment>
                <GenericMetadataParser className="incentive_details_page" componentMetadata={IncentiveDetailsMetadata()
                    .withIncentive(incentive)
                    .withTabsFunction(tabItemsFunction)
                    .withDateFunction(DateFunction)
                    .withPrintModal(<PrintModal />)
                    .withArrowBackFunction(ArrowClick)
                    .withExportFunction(ExportClick(incentive))
                    .isShowPrintView(true).build()
                } />
            </React.Fragment>
            : null;


    const noTabsDisplay = (printView) => {
        if (incentive) {
            let metadata = IncentiveDetailsMetadata()
                .withIncentive(incentive)
                .withTabsFunction(tabItemsFunction)
                .withDateFunction(DateFunction)
                .withPrintModal(<PrintModal component={tabsDisplay} />)
                .withExportFunction(ExportClick(incentive))
                .withArrowBackFunction(ArrowClick)
            metadata = (printView) ? metadata.isShowPrintView(true) : metadata
            return (
                    <React.Fragment>
                    <GenericMetadataParser className={printView ? "print_details_page GMHeaderComponent" : "GMHeaderComponent"} componentMetadata={headerMeta} />
                        <GenericMetadataParser className={printView ? "print_details_page" : "incentive_details_page"} id={printView ? "print_details_page" : "incentive_page"} componentMetadata={metadata.build()} />
                    </React.Fragment>
                )
        }
        return null
    }

    return (
        <React.Fragment>
            {error ? (
                <>Oh no, there was an error</>
            ) : isUninitialized ? (
                <></>
            ) : isLoading ? (
                <React.Fragment>
                    <center>...</center>
                </React.Fragment>
            ) : incentive ? (
                noTabsDisplay(false)
            ) : null}
            {noTabsDisplay(true)}
        </React.Fragment>
    )
}



export default IncentiveDetailsPage