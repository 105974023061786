import "./scss/main.scss";
import {useEffect} from 'react';

import { Flow, Flows, FlowSegment } from "react-flows";
import { unifiedApi } from "./services/unifiedAPIQuery";
import thunk from "redux-thunk";
import IncentiveDetailsPage from "./components/pages/IncentiveDetailsPage";
import VehicleCatalogPage from "./components/pages/VehicleCatalogPage";
import VehicleDetailsPage from "./components/pages/VehicleDetailsPage";
import CatalogPage from "./components/pages/CatalogPage";
import { IncentivePageNavigationSlice } from "./slices/IncentivePageNavigationSlice";
import GM_HomePage from "./components/pages/GMHomePage";
import { userAuthenticationSlice } from "./components/AwsAmplifyHelper/userAuthenticationSlice";
import { useAuthOauth2GrantsConfig } from "./components/AwsAmplifyHelper/AuthOauth2Grants";
import { OAuthGrantSlice } from "./components/AwsAmplifyHelper/OAuthGrantSlice";
import { CognitoApi } from "./components/AwsAmplifyHelper/CognitoService";
import { federatedOauth } from "./constants";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import GaTracker from "./utils/GATracker";

const INCENTIVE_PAGE_NAVIGATION = "IncentivePageNavigation";
const USER_AUTH_SLICE = "userAuthenticationSlice";

const Protected = (Component) =>
  function ProtectedRender(props) {
    const grant = useSelector((state) => state.userAuthenticationSlice.grant);

    return grant?.access_token ? <Component {...props} /> : <Redirect to="/" />;
  };

const App = () => {
  useAuthOauth2GrantsConfig(federatedOauth);

  useEffect(() => {    
    GaTracker.initialize();
    const page = window.location.pathname + window.location.search;
    GaTracker.trackPage(page, { });
  },[])

  const additionalReducers = {
    [unifiedApi.reducerPath]: unifiedApi.reducer,
    [INCENTIVE_PAGE_NAVIGATION]: IncentivePageNavigationSlice.reducer,
    [USER_AUTH_SLICE]: userAuthenticationSlice.reducer,
    [CognitoApi.reducerPath]: CognitoApi.reducer,
    [OAuthGrantSlice.name]: OAuthGrantSlice.reducer,
  };
  const middleware = [thunk, unifiedApi.middleware, CognitoApi.middleware];

  return (
    <div className="App">
      <Flows>
        <Flow
          name="default"
          middleware={middleware}
          additionalReducers={additionalReducers}
        >
          <FlowSegment
            className="login"
            name="login"
            exact
            link="/"
            component={GM_HomePage}
          />
          <FlowSegment
            className="incentive_details"
            name="IncentiveDetails"
            link="/incentive/:handle"
            component={Protected(IncentiveDetailsPage)}
          />
          <FlowSegment
            className="vehicle_details"
            name="VehicleDetails"
            link="/vehicle/:handle"
            component={Protected(VehicleDetailsPage)}
          />
          <FlowSegment
            className="vehicle_catalog"
            name="VehicleCatalog"
            exact
            link="/vehicles"
            component={Protected(VehicleCatalogPage)}
          />
          <FlowSegment
            className="catalog"
            name="Catalog"
            exact
            link="/incentives"
            component={Protected(CatalogPage)}
          />
        </Flow>
      </Flows>
    </div>
  );
};

export default App;
