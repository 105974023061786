import React from 'react'
import { Grid } from "@material-ui/core";

const GenericMetadataParser = (
    {
        className,
        id,
        componentMetadata,
        renderFunction = null
    }
) => {
    const renderSections = (sections) => {
        sections = (Array.isArray(sections)) ? sections : sections.sections

        const renderedSection = sections.map(item => {

            const elements = [];

            if ('sections' in item) elements.push(renderSections(item.sections));
            else if ('elements' in item) {
                item.elements.forEach((element, index) => {
                    elements.push(renderElement(element, index))
                });
            }

            item.props["children"] = elements;
            item.props["key"] = item.props.id

            return (
                React.createElement(item.uiComponent, item.props)
            )
        });
        return renderedSection;
    }

    const renderElement = (element) => {
        const gridProps = Object.assign({},
            element.gridItemProps, { item: true, children: React.createElement(element.uiComponent, element.props) });
        return (
            React.createElement(Grid, gridProps)
        );
    }

    const defaultRenderFunction = (reactArrayofSections) => {
        return (
            <div id={id} className={className} >
                {reactArrayofSections}
            </div>
        )
    }

    const doRender = renderFunction || defaultRenderFunction;
    return doRender(renderSections(componentMetadata));

}


export default GenericMetadataParser;