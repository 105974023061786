import { formFactorsList } from "../../constants/formFactors";

export const statusMap = new Map([
    ["All", ["all"]],
    ["Open", ["current"]],
    ["Waitlist", ["waitlist"]],
    ["Hiatus", ["hiatus"]],
    ["Closed", ["closed"]],
])

export const orgTypeMap = new Map([
    ["All Organizations",["all"]],
    ["Car Sharing Companies",["car_sharing_fleets"]],
    ["Federal Government Entities",["federal_government_entities"]],
    ["Local Government Entities",["local_government_entities"]],
    ["Logistics Companies",["logistics_companies"]],
    ["Medium or Large Businesses",["medium_or_large_businesses"]],
    ["Native Tribes",["native_tribes"]],
    ["Nonprofits",["nonprofits"]],
    ["Private School Districts",["private_school_districts"]],
    ["Public School Districts",["public_school_districts"]],
    ["Rental Companies",["car_rental_fleets"]],
    ["Small Businesses",["small_businesses"]],
    ["State Government Entities",["state_government_entities"]],
    ["Transit Agencies",["transit_agencies"]],
    ["Universities",["universities"]],
    ["Utilities",["utilities"]],

])

export const equipmentMap = new Map([
    ["All", ["all"] ],
    ["Vehicles",["vehicles"]],
    ["Chargers",["chargers"]]
])

export const vehicleSegmentMap = new Map([
    ["All Segments", formFactorsList],
    ["Small MPV",["Hatchback"]],
    ["Small SUV",["small_SUV"]],
    ["Midsize SUV", "mid_SUV"],
    ["Large Pickup","pickup_truck"],
    ["Large Luxury SUV",['large_SUV']],
    ["Cargo Van",["cargo_van"] ],
])
