import React from "react";
import { Grid } from "@material-ui/core";
import zaLogo from "../assets/images/zrLogo.png";
import GM_Blue_Horizontal_RGB from '../assets/GMLogosSVG/GM_Blue_Horizontal_RGB.svg'

const GM_Header = () => new _GM_Header();

export class _GM_Header {
    constructor() {
        this.inputs = {
        };

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach((input) => {
            // if booleanValue, it will have the property name became has
            let functionNameValue =
                typeof this.inputs[input] == "boolean"
                    ? `is${input.charAt(0).toUpperCase() + input.slice(1)}`
                    : `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            };
        });
    }

    build() {
        return {
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "GMHeaderComponent",
                        className: "GMHeaderComponent",
                    },
                    elements: [
                        {
                            gridItemProps: {
                              item: true,
                              xs: 12,
                            },
                            uiComponent: gmHeaderUI,
                        }
                    ],
                },
            ],
        }
    }
};


export default GM_Header;


const gmHeaderUI = () => {
    return (
        <div className="thisHeader">
            <img src={GM_Blue_Horizontal_RGB} alt="GM logo" className="GMHeaderLogo" />
            <div className="logoText">powered by<span><img src={zaLogo} id="logo" alt="zappyride brand"/></span></div>
        </div>
    )
}