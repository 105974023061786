import { Grid, Typography, Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import React from "react";


class _VehicleDetailsMetadata {
    constructor() {
        this.inputs = {
            vehicleHeroImg: "",
            vehicleTitle: "",
            msrp: "",
            vehicleType: "",
            weightClass: "",
            subtype: "",
            gvwr: "",
            cargoSpace: "",
            electricRange: "",
            payloads: "",
            totalRange: "",
            batteryCapacity: "",
            plugFormat: "",
            powertrain: "",
            batteryType: "",
            maxChargeRate: ""
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {
        return ({
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "VehicleDetailsPage",
                        className: "VehicleDetailsPage",
                        container: true,
                        spacing: 3,
                        item: true,
                        xs: 12,
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                id: "VehicleDetailsHero",
                                className: "vehicle-hero-details",
                                container: true,
                                spacing: 3
                            },
                            sections: [
                                {
                                    uiComponent: Grid,
                                    props: {
                                        id: "vehicle_hero_image",
                                        className: "vehicle-hero-image",
                                        item: true,
                                        xs: 12,
                                        md: 12,

                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,
                                                xs: 12,
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                className: "mobile-page-title",
                                                children: this.inputs.make 
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                                                xs: 12,
                                            },
                                            uiComponent: React.Fragment,
                                            props: {
                                                alt: "",
                                                // className: "vehicle-image",
                                                children: <img src={this.inputs.vehicleHeroImg} alt={this.inputs.make} />,
                                            },
                                        },
                                    ]
                                },
                                {
                                    uiComponent: Grid,
                                    props: {
                                        id: "vehicle_hero_image",
                                        className: "details-section",
                                        item: true,
                                        xs: 12,
                                        md: 4,
                                    },
                                    elements: [

                                        {
                                            gridItemProps: {
                                                item: true,
                                                xs: 12,
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                className: "page-title",
                                                children: this.inputs.vehicleTitle,
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                                                xs: 12,
                                                justifyContent: "center",
                                                className: "title",
                                            },
                                            uiComponent: InfoWrapper,
                                            props: {
                                                title: "MSRP",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                                                xs: 12,
                                                justifyContent: "center",
                                                className: "msrp-value",
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "$" + (this.inputs.msrp).toLocaleString()

                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                                                xs: 12,
                                                justifyContent: "center",
                                                className: "all-vehicles-button",
                                            },
                                            uiComponent: NavLink,
                                            props: {
                                                className: "all-vehicles-button",
                                                children: "View all Vehicles",
                                                to: "/"
                                            }

                                        }


                                    ]
                                }

                            ],
                        },

                        {
                            uiComponent: Grid,
                            props: {
                                id: "VehicleDetailsCards",
                                className: "info-wrapper",
                                container: true,
                                border: "solid",
                                elevation: 0,
                                spacing: 3
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                        md: 4,
                                        justifyContent: "center",
                                        className: "vehicle-info-box"
                                    },
                                    uiComponent: InfoWrapper,
                                    props: {
                                        title: "Classifications",
                                        titleProps: { className: 'title' },
                                        className: "info-wrapper",
                                        children: createInternalGridItems([{
                                            label: "Vehicle Type",
                                            value: this.inputs.vehicleType.replace("_", " ")
                                        }, {
                                            label: "Weight Class",
                                            value: this.inputs.weightClass
                                        }, {
                                            label: "Sub Type",
                                            value: this.inputs.subtype
                                        }])
                                    },
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                        md: 4,
                                        justifyContent: "center",
                                        className: "vehicle-info-box"

                                    },
                                    uiComponent: InfoWrapper,
                                    props: {
                                        title: "Capabilities",
                                        titleProps: { className: 'title' },
                                        className: "info-wrapper",
                                        children: createInternalGridItems([{
                                            label: "GVWR",
                                            value: this.inputs.gvwr ?  this.inputs.gvwr.toLocaleString() + " lbs" : null
                                        }, {
                                            label: "Cargo Space",
                                            value: [this.inputs.cargoSpace , <span> ft&#179;</span>]
                                        }, {
                                            label: "Electric Range",
                                            value: this.inputs.electricRange + " miles"
                                        },
                                        {
                                            label: "Payload",
                                            value: this.inputs.payloads ? this.inputs.payloads.toLocaleString() + " lbs" : null
                                        }, {
                                            label: "Total Range",
                                            value: this.inputs.totalRange + " miles"
                                        }])

                                    },
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                        md: 4,
                                        justifyContent: "center",
                                        className: "vehicle-info-box"
                                    },
                                    uiComponent: InfoWrapper,
                                    props: {
                                        title: "Electric Specs",
                                        titleProps: { className: 'title' },
                                        className: "info-wrapper",
                                        children: createInternalGridItems([{
                                            label: "battery capacity",
                                            value: this.inputs.batteryCapacity ? this.inputs.batteryCapacity + " kWh" : null
                                        }, {
                                            label: "Plug Format",
                                            value: this.inputs.plugFormat
                                        }, {
                                            label: "Powertrain",
                                            value: this.inputs.powertrain
                                        },
                                        {
                                            label: "Battery Type",
                                            value: this.inputs.batteryType
                                        }, {
                                            label: "Max Charging Rate (AC/DC)",
                                            value: this.inputs.maxChargeRate ? this.inputs.maxChargeRate + " kWh" : null
                                        }])

                                    },
                                }

                            ]

                        }
                    ]
                }
            ]
        })
    }
}





const InfoWrapper = ({ title, titleProps, children }) => {
    titleProps = (titleProps) ? titleProps : {};
    titleProps['children'] = title;
    return (
        <React.Fragment>
            <Box >
                {React.createElement(Typography, titleProps)}
            </Box>
            <Box className="value">
                {children}
            </Box>
        </React.Fragment>

    )
}

const createInternalGridItems = (properties) => {
    return (
        <Grid xs={12} container className="details-container">
            {properties.map(prop => (
                <Grid item xs={6} id="label"className="details" >
                    <InfoWrapper title={prop.label} className="value">{prop.value}</InfoWrapper>
                </Grid>
            ))
            }
        </Grid>
    )

}




const VehicleDetailsMetadata = (new _VehicleDetailsMetadata())

export default VehicleDetailsMetadata;
