import React from 'react';

import { DataGrid } from '@mui/x-data-grid';



const IncentiveCatalogGrid = ({ indexes, data, sortFunction = null, itemsFoundText = "Total Found: ", idField = "incentive_handle" }) => {
    let catalogItems = React.useMemo(() => indexes.map((index) => Object.assign({ id: data[index][idField] },
        data[index])), [indexes, data, idField])

    if (sortFunction) {
        catalogItems = catalogItems.sort(sortFunction)
        
    }
    catalogItems.forEach((ele) => {

        let update_year = ele.changelog_date.slice(0, 4), review_year = ele.next_scheduled_review.slice(0, 4)
        let update_month_day = ele.changelog_date.slice(5), review_month_day = ele.next_scheduled_review.slice(5)
        let new_date = update_month_day.replaceAll('-', '/') + "/" + update_year, update_date = review_month_day.replaceAll('-', '/') + "/" + review_year

        ele.changelog_date = new_date
        ele.next_scheduled_review = update_date
    })

    const columns = [
        {
            field: 'changelog_date',
            headerClassName: "columnTitle",
            headerName: 'DATE',
            width: 175,
        },
        {
            field: 'changelog_description',
            headerClassName: "columnTitle",
            headerName: 'CHANGES',
            width: 420,
        },
        {
            field: 'anticipated_changes',
            headerClassName: "columnTitle",
            headerName: 'ANTICIPATED FUTURE CHANGE',
            width: 380,
        },
        {
            field: 'next_scheduled_review',
            headerClassName: "columnTitle",
            headerName: 'NEXT UPDATE',
            width: 225,
        },
    ];

    return (
        <React.Fragment>
            <div className="catalog" id="changelog_catalog">


                <div className="data_grid_details" id="data_grid_details">

                    <DataGrid
                        rows={catalogItems}
                        columns={columns}
                        rowsPerPageOptions={[100]}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}



export default IncentiveCatalogGrid;



