import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown'

const IncentiveEligibilityTab = () => (new _IncentiveEligibilityTab());
class _IncentiveEligibilityTab {
    constructor() {
        this.inputs = {
            eligibleProjectType: "",
            eligibleProjectDescription: "",
            eligibilityAssumptions: "",
            eligibleVehicleType: "",
            eligibleVehicleDescription: "",
            eligibleVehicleDuties: "",
            eligibleVehicleWeightClasses: "",
            eligibleVehicleFuelTypes: "",
            eligibleVehicleSegments: "",
            eligibleOrganizations: "",
            eligibleLease: true,
            leaseBeneficiary: "",
            segmentFunction: () => {},
            }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `is${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        const markdown = `${this.inputs.eligibilityAssumptions}`
        let segment = this.inputs.segmentFunction(this.inputs.eligibleVehicleSegments)
        return ({
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "EligibilityTabPage",
                        className: "EligibilityTabPage",
                        container: true,
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                item: true,
                                xs: 12,
                                id: "EligibilityTabTitleTop",
                                container: true
                            },
                            sections: [
                                {
                                    uiComponent: Grid,
                                    props: {
                                        id: "EligibilityTabTitleTopLeft",
                                        container: true
                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,
    
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "PROJECT TYPE ELIGIBILITY",
                                                className: "mainTitle"
                                            }
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
    
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligible Project Type",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
    
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleProjectType ? this.inputs.eligibleProjectType.join(', ').replaceAll('_', ' ').replace('mud', 'MUD') : "N/A",
                                                className: "mainText"
                                            },

                                        }
                                    ]
                                },
                                {
                                    uiComponent: Grid,
                                    props: {
                                        id: "EligibilityTabTitleTopRight",
                                        container: true
                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,
    
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligible Project Description",
                                                className: "mainsubTitle"
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
    
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleProjectDescription ? this.inputs.eligibleProjectDescription: "N/A",
                                                className: "mainText"
                                            },

                                        }
                                    ]
                                },
                            ]
                        }, {
                            uiComponent: Grid,
                            props: {

                                id: "EligibilityTabTitleMiddle",
                                container: true
                            },
                            sections: [
                                {
                                    uiComponent: Grid,
                                    props: {
        
                                        id: "EligibilityTabTitleMiddleLeft",
                                        container: true
                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "VEHICLE ELIGIBILITY",
                                                className: "mainTitle"
                                            }
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligible Vehicle Type",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleVehicleType ? this.inputs.eligibleVehicleType.join(', ') : "N/A",
                                                className: "mainText"
                                            },

                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligible Vehicle Description",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleVehicleDescription ? this.inputs.eligibleVehicleDescription : "N/A",
                                                className: "mainText"
                                            },

                                        }
                                    ]
                                },
                                {
                                    uiComponent: Grid,
                                    props: {
        
                                        id: "EligibilityTabTitleMiddleCenter",
                                        container: true
                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligible Vehicle Duties",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleVehicleDuties ? this.inputs.eligibleVehicleDuties.join(', ') : "N/A",
                                                className: "mainText"
                                            },

                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligible Vehicle Weight Classes",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleVehicleWeightClasses ? this.inputs.eligibleVehicleWeightClasses.join(', ') : "N/A",
                                                className: "mainText"
                                            },
                                        }
                                    ]
                                },
                                {
                                    uiComponent: Grid,
                                    props: {
        
                                        id: "EligibilityTabTitleMiddleRight",
                                        container: true
                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligible Vehicle Fuel Types",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleVehicleFuelTypes ? this.inputs.eligibleVehicleFuelTypes.join(', ') : "N/A",
                                                className: "mainText"
                                            },

                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligible Vehicle Segments",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleVehicleSegments !== 'n/a' ? segment : "N/A",
                                                className: "mainText"
                                            },
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "EligibilityTabTitleBottom",
                                item: true,
                            },
                            sections: [
                                {
                                    uiComponent: Grid,
                                    props: {
        
                                        id: "EligibilityTabTitleBottomLeft",
                                        container: true
                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,

                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "OTHER ELIGIBILITY DETAILS",
                                                className: "mainTitle"
                                            }
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligible Organizations",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleOrganizations ? this.inputs.eligibleOrganizations.join(', ').replaceAll('_', ' ') : "N/A",
                                                className: "mainText"
                                            },

                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Lease Eligible",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.eligibleLease ? "Yes" : "No",
                                                className: "mainText"
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Lease Beneficiary",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
                
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.leaseBeneficiary ? this.inputs.leaseBeneficiary : "N/A",
                                                className: "mainText"
                                            },
                                        }
                                    ]
                                },
                                {
                                    uiComponent: Grid,
                                    props: {
                                        id: "EligibilityTabTitleBottomRight",
                                        container: true
                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,
    
                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Eligibility Assumptions",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,
    
                                            },
                                            uiComponent: Box,
                                            props: {
                                                children: <ReactMarkdown className={"markdown mainText"} children={markdown}/>
                                            },
                                        }
                                    ]
                                },  
                            ]
                        },
                    ]
                }
            ]
        })
    }
}



export default IncentiveEligibilityTab;
