import { Box, Button, Grid, Typography, AppBar, Tab, Tabs} from "@material-ui/core";

import React from "react";
import TabPanel from "../components/TabPanel";

import { useDispatch, useSelector } from 'react-redux';
import { updaters, retrievers } from '../slices/IncentivePageNavigationSlice';
 
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
const IncentiveTabPanels = ({tabsFunction = () => {}, incentive}) => {
    const dispatch = useDispatch();
    const currentTab = useSelector(retrievers.currentTab);

  const handleChange = (event, newValue) => {
        dispatch(updaters.currentTab(newValue));
  };
    let tabList = tabsFunction(incentive);

    let tabs = [], tabPanels = [];
    tabList.forEach((tab, index) => {
        tabs.push(<Tab key={tab.id} label={tab.id} {...a11yProps(index)} />)
        tabPanels.push(
            <TabPanel key={tab.id} value={currentTab} index={index}>
                {tab.component}
            </TabPanel>)
    })

    return (
        <React.Fragment>
            <AppBar position="static">
                <Tabs className="main-tabs" id="tabsDisplay" value={currentTab} onChange={handleChange} aria-label="simple tabs example">
                    {tabs}
                </Tabs>
            </AppBar>
            {tabPanels}
        </React.Fragment>
    )
}
const Incentives = ({ tabsFunction = () => {}, incentive }) => {
    const currentTab = useSelector(retrievers.currentTab);

    let tabList = tabsFunction(incentive);

    let tabPanels = [];
    tabList.forEach((tab, index) => {
        tabPanels.push(
            <React.Fragment key={tab.id} value={currentTab} index={index} >
                {tab.component}
            </React.Fragment>)
    })

    return (
        <React.Fragment>
            <AppBar position="static">
            </AppBar>
            {tabPanels}
        </React.Fragment>
    )
}

export class _IncentiveDetailsMetadata {

    constructor() {
        this.inputs = {
            incentive: {},
            DateFunction: () => {},
            tabsFunction: () => {},
            component: {},
            currentTab: 0,
            setCurrentTabFunction: () => {},
            limitation: 0, 
            arrowBackFunction: () => {},
            exportFunction: {},
            printModal: {},
            showPrintView: false
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `is${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        let incentive = this.inputs.incentive;
        let date_info = this.inputs.DateFunction(incentive.start_date, incentive.expiration_date, incentive.current_program_status)

        let grantorType = "";
        switch(incentive.grantor_type) {
            case "country":
                grantorType = "Country";
                break;
            case "state":
                grantorType = "State";
                break;
            case "utility":
                grantorType = "Utility";
                break;
            case "miscellaneous":
                grantorType = "Local";
                break;
            default:
                grantorType = "GRANTOR";
        }

        let status = "";
        switch(incentive.current_program_status) {
            case "current":
                status = "Open";
                break;
            case "suspended":
                status = "Closed";
                break;
            case "expired":
                status = "Closed";
                break;
            case "waitlist":
                status = "Wait List";
                break;
            default:
                status = "";
        }

        let deadline = "";
        if(incentive.expiration_date !== "2038-01-01") {
            deadline = "Open until " + date_info[0];
        } else if(incentive.expiration_date === "2038-01-01" && date_info[1] === 0) {
            deadline = "Closed";
        } else if(incentive.current_program_status === "waitlist") {
            deadline = "Wait List";
        } else if(incentive.current_program_status === "hiatus") {
            deadline = "Hiatus";
        } else {
            deadline = "Open - no deadline";
        }

        return ({
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "IncentiveDetails",
                        className: "catalog_details",
                        container: true,
                        item: true,
                        xs: 12
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                className: "Page_Title_Row",
                                container: true,
                                item: true,
                                // xs: 12
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 1,
                                        md: 1
                                    },
                                    uiComponent: Box,
                                    props: {
                                        className: "back_button",
                                        children: this.inputs.arrowBackFunction,
                                    },
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 10,
                                        md: 9
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "page_title",
                                        children: incentive.incentive_name
                                    },
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                    },
                                    uiComponent: Box,
                                    props: {
                                        className: "clickable_button",
                                        children: this.inputs.exportFunction,
                                    },
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                    },
                                    uiComponent: React.Fragment,
                                    props: {
                                        children: this.inputs.printModal,
                                    },
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "rebateTag",
                                className: "catalog_details overview_card_row",
                                container: true,
                                item: true,
                                spacing: 3,
                                xs: 12
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        className: "rebate_label",
                                        children: "REBATE"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 8,
                                    },
                                    uiComponent: React.Fragment,
                                    props: {
                                        children: <Box className="rebate_title"><Typography className="rebate_title_text">{incentive.typical_amount}</Typography></Box>
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 4,
                                    },
                                    uiComponent: React.Fragment,
                                    props: {
                                        children: <Box className="limitation_ammount"><Typography>{this.inputs.limitation} max</Typography></Box>

                                    }
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "keyFactsCardsLeft",
                                className: "catalog_details_box",
                                container: true,
                                item: true,
                                spacing: 4,
                                md: 3
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Box,
                                    props: {
                                        className: "multi_item_title",
                                        children: <React.Fragment><Typography className="titleleft">STATUS</Typography> <Typography className={incentive.current_program_status === "current" ? "Open titleright" : incentive.current_program_status === "suspended" || incentive.current_program_status === "expired" ? "Closed titleright" : "Hiatus titleright"}>{status}</Typography></React.Fragment>
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: React.Fragment,
                                    props: {
                                        children: <React.Fragment><Box className="rebate_title"><Typography className="catalog_details_info">{incentive.total_funding ? "$" + incentive.total_funding.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "No Funding Data Available"}</Typography></Box><Box><Typography className="catalog_details_info_subheader">{incentive.total_funding ? "remaining" : ""}</Typography></Box></React.Fragment>
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 4,

                                    },
                                    uiComponent: Box,
                                    props: {
                                        children: <Box></Box>

                                    }
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "keyFactsCardsMiddle",
                                className: "catalog_details_box",
                                container: true,
                                item: true,
                                spacing: 4,
                                md: 3
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,

                                    },
                                    uiComponent: Box,
                                    props: {
                                        className: "multi_item_title",
                                        children: <React.Fragment><Typography className="titleleft">{grantorType}</Typography><Typography className="titleright"></Typography> </React.Fragment>
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Box,
                                    props: {
                                        children: <React.Fragment><Box className="titleleft"><Typography className="catalog_details_info">{incentive.grantor_name}</Typography></Box></React.Fragment>
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,

                                    },
                                    uiComponent: Box,
                                    props: {
                                        children: <Box></Box>

                                    }
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "keyFactsCardsRight",
                                className: "catalog_details_box",
                                container: true,
                                item: true,
                                spacing: 4,
                                md: 4
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Box,
                                    props: {
                                        className: "multi_item_title",
                                        children: <React.Fragment><Typography className="titleleft">Deadline</Typography><Typography style={{ color: `${date_info[2]}` }} className="titleright"> {date_info[1] === 0 || -1 ? "" : date_info[1] + " Days Left"}</Typography></React.Fragment>
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Box,
                                    props: {
                                        children: <React.Fragment><Box className="title"><Typography className="catalog_details_info">{deadline}</Typography></Box></React.Fragment>
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Box,
                                    props: {
                                        children: incentive.incentive_application_url ? <Button className="apply_button"> Apply </Button> : null,
                                        onClick: () => { window.open(incentive.incentive_application_url) }
                                    }
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "keyFactsCardsBottom",
                                className: "catalog_details overview_card_row",
                                container: true,
                                item: true,
                                xs: 12
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                        md: 12
                                    },
                                    uiComponent: this.inputs.showPrintView ? Incentives : IncentiveTabPanels,
                                    props: {
                                        tabsFunction: this.inputs.tabsFunction,
                                        incentive: this.inputs.incentive
                                    }
                                },                                 
                            ]
                        },
                    ]
                }
            ]
        })
    }
}

const IncentiveDetailsMetadata = () => (new _IncentiveDetailsMetadata());

export default IncentiveDetailsMetadata;


