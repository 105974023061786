import { Typography, Button, Grid, MenuItem, TextareaAutosize } from "@material-ui/core"
import { TextField, Select } from 'mui-rff';



export const ContactFormMetadataBuilder = () => (new _ContactFormMetadataBuilder());
class _ContactFormMetadataBuilder {
    constructor() {
        this.inputs = {
            onSubmitFunction: () => { },
            onShowLoginClick: () => { },
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {
        return (
            ContactFormMetadata(this.inputs.onSubmitFunction, this.inputs.onShowLoginClick)
        )
    }
}


export const ContactFormMetadata = (onSubmit, onShowLoginClick) => {
    return (
        {
            onSubmit: onSubmit,
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "createAnAccount",
                        className: "create_account",
                        container: true,
                        alignItems: "flex-start",
                        spacing: 2
                    },
                    elements: [
                        {
                            size: 12,
                            uiComponent: Typography,
                            props: {
                                text: "Form Title",
                                className: "headline",
                                children: "Form Title",
                            }
                        },
                        {

                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersFirstName",
                                className: "first_name",
                                type: "text",
                                required: true,
                                placeholder: "John",
                                label: "First Name"
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersLastName",
                                type: "text",
                                required: true,
                                placeholder: "Doe",
                                label: "Last Name",

                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersEmail",
                                type: "email",
                                required: true,
                                placeholder: "jdoe@email.com",
                                label: "Email",
                                
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersPhone",
                                type: "phone number",
                                placeholder: "123-456-7890",
                                // required: true,
                                label: "Phone",
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersCompany",
                                type: "text",
                                placeholder: "Acme Co.",
                                // required: true,
                                label: "Company",
                            }
                        },
                        {
                            size: 6,
                            uiComponent: Select,
                            props: {
                                name: "usersFleetSize",
                                label: "Fleet Size",
                                component: "select",
                                placeholder: "0-10 Vehicles",
                                children: (
                                    [
                                        <MenuItem  value={10}>0-10 Vehicles</MenuItem>,
                                        <MenuItem value={20}>10-20 Vehicles</MenuItem>,
                                        <MenuItem  value={30}>20-30 Vehicles</MenuItem>,
                                    ]
                                ),
                            }
                        },
                        {
                            size: 6,
                            uiComponent: TextField,
                            props: {
                                name: "usersZip",
                                type: "text",
                                placeholder: "90210",
                                // required: true,
                                label: "Zip Code",
                            }
                        },
                        {
                            size: 10,
                            uiComponent: TextareaAutosize,
                            props: {
                                name: "usersComments",
                                className: "commentTextArea",
                                type: "textArea",
                                minRows: 5,
                                placeholder: "Comments Here",
                                // required: true,
                                label: "Comments",
                            }

                        },
                        
                        {
                            uiComponent: Button,
                            props: {
                                type: "submit",
                                className: "contactFormSubmit",
                                children: "Submit"
                            }
                        }
                    ]
                }
            ]
        })
}
