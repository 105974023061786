import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getGrantFromCode } from "./FederatedUser/FederatedUser";

const initialState = {
  status: "idle",
  grant: null,
  expires_at: Date.now(),
  currentRequestId: undefined,
  error: null,
};

const stateRetrievers = {};
const reducers = {};

Object.keys(initialState).forEach((key) => {
  stateRetrievers[key] = (state) => {
    if (!state.OAuthGrant) {
      return null;
    }
    return state.OAuthGrant[key];
  };

  reducers[key] = (state, action) => {
    state[key] = action.payload;
  };
});

export const fetchTokenGrant = createAsyncThunk(
  "OAuthGrant/fetchTokenGrantStatus",
  async (token, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState().OAuthGrant;
    if (status !== "pending" || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await getGrantFromCode(token);
      return response;
    } catch (e) {
      let error = e;
      if (!error.response) {
        throw e;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      rejectWithValue(error.response);
    }
  }
);

export const OAuthGrantSlice = createSlice({
  name: "OAuthGrant",
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokenGrant.pending, (state, action) => {
        if (state.status === "idle") {
          state.status = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
      .addCase(fetchTokenGrant.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.status === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.status = "idle";
          state.grant = action.payload;
          state.expires_at = action.payload.expires_in * 1000 + Date.now();
        }
      })
      .addCase(fetchTokenGrant.rejected, (state, action) => {
        const { requestId } = action.meta;

        if (
          action.payload &&
          state.status === "pending" &&
          state.currentRequestId === requestId
        ) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.error = action.payload.errorMessage;
        } else {
          state.status = "idle";
          state.error = action.error.message;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const OAuthGrantReducers = OAuthGrantSlice.actions;

export const OAuthGrantRetrievers = stateRetrievers;

export default OAuthGrantSlice.reducer;
