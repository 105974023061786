import { federatedOauth as oAuthConfig } from "../../../constants";

function formatCognitoOauthBody(body: Map<string, string>) {
  let values: Array<string> = [];
  body.forEach((value, key) => {
    values.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
  });
  return values.join("&");
}

async function getFederatedUserData(
  body: string,
  cognitoUrl: string,
  cognitoUrlEndpiont: string
): Promise<AuthorizationCodeGrant> {
  try {
    const response: Response = await fetch(
      `${cognitoUrl}/${cognitoUrlEndpiont}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: body,
      }
    );
    const data = response.json();
    return data;
  } catch (error: any) {
    return error;
  }
}

interface AuthorizationCodeGrant {
  access_token: string;
  expires_in: string;
  id_token: string;
  refresh_token: string;
  token_type: string;
}

export async function getGrantFromCode(
  code: string
): Promise<AuthorizationCodeGrant> {
  // let params: URLSearchParams = new URLSearchParams(document.location.search);

  if (!oAuthConfig) {
    throw new Error("AuthOauth2GrantCofig was not set");
  }
  const body = new Map([
    ["grant_type", "authorization_code"],
    ["code", code],
    ["client_id", oAuthConfig.clientId ?? ""],
    ["redirect_uri", oAuthConfig.redirectUri ?? ""],
  ]) as Map<string, string>;

  return getFederatedUserData(
    formatCognitoOauthBody(body),
    oAuthConfig.cognitoUrl,
    "oauth2/token"
  );
}
