import { FC } from "react";
import ChargerIcon from "../../../assets/icons/chargeIcon.svg";
import CargoVanIcon from "../../../assets/icons/CargoVan.png";
import SUVIcon from "../../../assets/icons/Icon_SUV.png";
import { SUV, CARGO_VAN } from "../../../constants/formFactors";
import { CHARGER } from "../../../constants/incentiveFocuses";
import { ALL } from "../../../constants/labels";

const ICON_CLASSNAME = "eligible-equipment-icon__img";
export const VEHICLE_ALT_TEXT = ALL;
export const CHARGER_ALT_TEXT = CHARGER;

export type IncentiveFocuses = "vehicles" | "chargers";

export interface RowData {
  is_eligible_to_specified_form_factors_only: Array<string>;
  incentive_focus: Array<IncentiveFocuses>;
}

export interface EligibleEquipmentCellProps {
  row: RowData;
}

export const segmentFormFactor = (
  factors: Array<string>
): typeof SUV | typeof CARGO_VAN =>
  factors.includes(CARGO_VAN) ? CARGO_VAN : SUV;

export interface VehicleIconProps {
  formFactors: Array<string>;
}

export const VehicleIcon: FC<VehicleIconProps> = ({ formFactors }) => (
  <img
    className={ICON_CLASSNAME}
    src={segmentFormFactor(formFactors) === CARGO_VAN ? CargoVanIcon : SUVIcon}
    alt={VEHICLE_ALT_TEXT}
  />
);

export interface IconSwitchProps {
  formFactors: Array<string>;
  focus: IncentiveFocuses;
}

export const IconSwitch: FC<IconSwitchProps> = ({ formFactors, focus }) => {
  switch (focus) {
    case "chargers":
      return (
        <img
          className={ICON_CLASSNAME}
          alt={CHARGER_ALT_TEXT}
          src={ChargerIcon}
        />
      );
    default:
      return <VehicleIcon formFactors={formFactors} />;
  }
};

export const EligibleEquipmentCell: FC<EligibleEquipmentCellProps> = ({
  row: {
    is_eligible_to_specified_form_factors_only: formFactors,
    incentive_focus: focus,
  },
}) => {
  return (
    <div className="eligible-equipment-icon--container">
      {focus.map((item, i) => (
        <IconSwitch
          key={`${item}-${i}`}
          formFactors={formFactors}
          focus={item}
        />
      ))}
    </div>
  );
};
