    import React from 'react';
    import { Grid } from '@material-ui/core';

const Catalog = ({ indexes, data, sortFunction = null, cardRender, containerProps, itemsFoundText = "Total Found: ", additionalClassNames}) => {
    let catalogItems = React.useMemo(() => indexes.map((index) => data[index]), [indexes, data])

    if (sortFunction) {
        catalogItems = catalogItems.sort(sortFunction)
    }
    
    containerProps["children"] =
        catalogItems.map((item) => {
            return (
                <Grid item xs={12} md={4}>
                    {cardRender(item)}
                </Grid>)
        })

    let container = React.createElement(Grid, containerProps)

    return (
        <div className={`catalog ${additionalClassNames}`}>
            {/* <Box className="catalog_total_found"><Typography className="total_found">{itemsFoundText} {indexes.length}</Typography></Box> */}
            {container}
        </div>
    )
}


export default Catalog;
