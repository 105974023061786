import React, { useState } from "react";
import { Typography, Grid, Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FlowComponentWrapper } from "react-flows";
import GenericMetadataParser from "../../GenericMetadataParser";
import GM_Header from "../../../ComponentMetadata/HeaderMetadata";
import { filterValues } from "../../catalog/catalogUtilsEdit";
import { useGetGMCommercialIncentivesQuery } from "../../../services/unifiedAPIQuery";
import CatalogGrid from "../../catalog/CatalogGrid";
import CatalogPageMetadata from "../../../ComponentMetadata/CatalogPageMetadata";
import {
  statusMap,
  orgTypeMap,
  equipmentMap,
  vehicleSegmentMap,
} from "../../filters/FiltersDropdown";
import TOOLTIPS from "../../../constants/tooltips";
import { ALL } from "../../../constants/labels";
import { CHARGERS } from "../../../constants/incentiveFocuses";
import TooltipMetadata from "../../../ComponentMetadata/ToolTipMetadata";
import TriggersTooltips from "../../catalog/MUITooltip";
import CommercialIncentiveSlice, {
  updaters,
  retrievers,
  valueHasAllFormFactors,
  getFilterableFormFactorsValue,
  INCENTIVE_FOCUS,
  IS_ELIGIBLE_TO_SPECIFIED_FORM_FACTORS_ONLY,
} from "../../../slices/CommercialIncentiveSlice";
import { SelectContainer } from "./SelectContainer";
import { Tooltip } from "./Tooltip";

const {
  statusTooltip,
  organizationTypeTooltip,
  equipmentTooltip,
  vehicleSegmentTooltip,
  locationTooltip,
} = TOOLTIPS;

const incentiveFilterFieldNames = [
  INCENTIVE_FOCUS,
  "current_program_status",
  "eligible_organizations",
  IS_ELIGIBLE_TO_SPECIFIED_FORM_FACTORS_ONLY,
];

const shouldResetFormFactors = (targetState, value) =>
  (targetState === INCENTIVE_FOCUS && value === CHARGERS) ||
  (targetState === IS_ELIGIBLE_TO_SPECIFIED_FORM_FACTORS_ONLY &&
    valueHasAllFormFactors(value));

const IncentivesCatalogWrapper = () => {
  const postcode = useSelector(retrievers.postcode);
  const form_factor = useSelector(retrievers.form_factor);
  const [postcodeInput, setPostcodeInput] = useState(postcode);
  const { data, error, isLoading } = useGetGMCommercialIncentivesQuery({
    postcode,
    form_factor,
  });

  const current_program_status = useSelector(retrievers.current_program_status);
  const eligible_organizations = useSelector(retrievers.eligible_organizations);
  const incentive_focus = useSelector(retrievers.incentive_focus);
  const is_eligible_to_specified_form_factors_only = useSelector(
    retrievers.is_eligible_to_specified_form_factors_only
  );

  const incentiveFilterFieldNameToValueMapping = [
    incentive_focus,
    current_program_status,
    eligible_organizations,
    getFilterableFormFactorsValue(is_eligible_to_specified_form_factors_only),
  ];

  const dispatch = useDispatch();

  function postCodeInputOnChangeHandler(event) {
    const { value } = event.target;
    const capitilizedValue = value.toUpperCase();
    const valueNoDashOrSpaces = capitilizedValue.replace(/-|\s/g, "");
    const trimmedValue = valueNoDashOrSpaces.substring(0, 6);
    setPostcodeInput(trimmedValue);
  }

  function onPostcodeSubmitClick() {
    dispatch(updaters.postcode(postcodeInput));
  }

  const updatePostcode = (title, tooltipMetadata) => {
    return (
      <Grid item xs={12} md={2} id="postcode_input">
        <div className="title_with_tooltip_display">
          <Typography className="input_title">{title}</Typography>
          <span className="location-tooltip">
            <TriggersTooltips
              tooltipMetadata={<Tooltip metadata={tooltipMetadata} />}
            />
          </span>
        </div>
        <TextField
          className="input_text_field"
          value={postcodeInput}
          onChange={(event) => postCodeInputOnChangeHandler(event)}
          variant="standard"
          error={postcodeInput.length > 6}
          autoFocus
          onKeyUp={(event) => {
            if (event.key === "Enter") onPostcodeSubmitClick();
          }}
        />
      </Grid>
    );
  };
  const renderButton = (title) => {
    return (
      <Grid item xs={12} md={2} id="incentive-button">
        <Button
          id="update-button"
          variant="contained"
          onClick={() => onPostcodeSubmitClick()}
        >
          {title}
        </Button>
      </Grid>
    );
  };

  const toolTipMeta = TooltipMetadata().withInfoArray(statusTooltip).build();
  const orgTipMeta = TooltipMetadata()
    .withInfoArray(organizationTypeTooltip)
    .build();
  const equipmentMeta = TooltipMetadata()
    .withInfoArray(equipmentTooltip)
    .build();
  const vehicleSegmentMeta = TooltipMetadata()
    .withInfoArray(vehicleSegmentTooltip)
    .build();
  const locationMeta = TooltipMetadata().withInfoArray(locationTooltip).build();

  const handleFormFactorsResetIfNeeded = (targetState, value) => {
    if (!shouldResetFormFactors(targetState, value)) {
      return;
    }

    if (targetState !== IS_ELIGIBLE_TO_SPECIFIED_FORM_FACTORS_ONLY) {
      dispatch(updaters[targetState](value));
    }

    dispatch(updaters.is_eligible_to_specified_form_factors_only(ALL));
  };

  const showSpecialtyHandler = (targetState) => (event) => {
    const { value } = event.target;

    handleFormFactorsResetIfNeeded(targetState, value);
    dispatch(updaters[targetState](value));
  };

  const headerMeta = GM_Header().build();
  const metadata = CatalogPageMetadata()
    .withFilters(() => {
      return (
        <React.Fragment>
          {isLoading ? (
            <React.Fragment>
              <center>...</center>
            </React.Fragment>
          ) : data ? (
            <Grid container className="dropdown-filters">
              <SelectContainer
                title="Status"
                tooltipMetadata={toolTipMeta}
                map={statusMap}
                targetState="current_program_status"
                onChange={showSpecialtyHandler("current_program_status")}
              />
              <SelectContainer
                title="Organization Type"
                tooltipMetadata={orgTipMeta}
                map={orgTypeMap}
                targetState="eligible_organizations"
                onChange={showSpecialtyHandler("eligible_organizations")}
              />
              <SelectContainer
                title="Equipment"
                tooltipMetadata={equipmentMeta}
                map={equipmentMap}
                targetState={INCENTIVE_FOCUS}
                onChange={showSpecialtyHandler(INCENTIVE_FOCUS)}
              />
              <SelectContainer
                title="Vehicle Segment"
                tooltipMetadata={vehicleSegmentMeta}
                map={vehicleSegmentMap}
                targetState={IS_ELIGIBLE_TO_SPECIFIED_FORM_FACTORS_ONLY}
                onChange={showSpecialtyHandler(
                  IS_ELIGIBLE_TO_SPECIFIED_FORM_FACTORS_ONLY
                )}
                disabled={incentive_focus === CHARGERS}
              />

              {updatePostcode("Location", locationMeta)}
              {renderButton("Update Incentives")}
            </Grid>
          ) : null}
        </React.Fragment>
      );
    })
    .withComponent(() => {
      return (
        <React.Fragment>
          {error ? (
            <ErrorOverlay postcode={postcode} />
          ) : data ? (
            <React.Fragment>
              <CatalogGrid
                idField={"incentive_handle"}
                itemsFoundText={" Incentives Found"}
                indexes={filterValues(
                  data.incentives,
                  incentiveFilterFieldNames,
                  incentiveFilterFieldNameToValueMapping
                )}
                className="data-grid-results"
                data={data.incentives}
                containerProps={{ lg: 12, container: true, spacing: 3 }}
                postcode={postcode}
              />
            </React.Fragment>
          ) : null}
        </React.Fragment>
      );
    })
    .withPageTitle("Electric Vehicle Incentives")
    .withPageSubTitle(
      "Search for incentives that may significantly reduce the cost of vehicles or chargers."
    )
    .withDisclaimer(() => {
      return (
        <>
          {!error && (
            <>
              *The eligibility and incentive amounts shown are indicative based
              on information published on incentive grantor’s websites. They
              represent guidance only. These are in no way a guarantee of
              eligibility or incentive amount. The incentive grantor should be
              contacted directly for additional guidance on eligibility or
              incentive amount.
            </>
          )}
        </>
      );
    })
    .build();

  return (
    <React.Fragment>
      <GenericMetadataParser
        className="GMHeaderComponent"
        componentMetadata={headerMeta}
      />
      <GenericMetadataParser
        className="vehicle_catalog"
        componentMetadata={metadata}
      />
    </React.Fragment>
  );
};

const WrappedIncentivesCatalog = FlowComponentWrapper(
  "CommercialIncentive",
  CommercialIncentiveSlice
)(IncentivesCatalogWrapper);

export default WrappedIncentivesCatalog;

const overlayComponentText = ({ postcode }) =>
  `Invalid location - post/zip code ${postcode} is not valid`;

const ErrorOverlay = (postcode) => {
  return (
    <div className="no_data_text" id="error">
      {overlayComponentText(postcode)}
    </div>
  );
};
