import { Grid, Typography, FormControl, Select } from "@material-ui/core";
import { useSelector } from "react-redux";
import TriggersTooltips from "../../../catalog/MUITooltip";
import { retrievers } from "../../../../slices/CommercialIncentiveSlice";
import { Tooltip } from '../Tooltip'

export const SelectContainer = ({
  title,
  tooltipMetadata,
  map,
  targetState,
  onChange,
  disabled = false,
}) => {
  return (
    <Grid
      item
      xs={12}
      md={2}
      className="table_sort"
      style={disabled ? { pointerEvents: "none", opacity: "0.5" } : {}}
    >
      <div className="title_with_tooltip_display">
        <Typography>{title}</Typography>
        <TriggersTooltips
          tooltipMetadata={<Tooltip metadata={tooltipMetadata} />}
        />
      </div>
      <FormControl variant="outlined">
        <Select
          native
          value={useSelector(retrievers[targetState])}
          onChange={onChange}
          inputProps={{
            name: targetState,
            id: targetState,
          }}
          className="dropdown_filter"
          disabled={disabled}
        >
          {Array.from(map.keys()).map((key, i) => {
            return (
              <option value={map.get(key)} key={`${key}-${i}`}>
                {key}{" "}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};
