import { Grid, Typography } from "@material-ui/core";


const IncentiveChangelogTab = () => (new _IncentiveChangelogTab());

class _IncentiveChangelogTab {

    constructor() {
        this.inputs = {
            component: {},
            pageTitle: "",
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `is${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        return ({
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        container: true,
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                id: "Catalog",
                                container: true,
                            },
                            elements: [
                                {
                                gridItemProps: {
                                    xs: 12,
                                },
                                uiComponent: Typography,
                                props: {
                                    className: "catalog_title",
                                    children: this.inputs.pageTitle,
                                },
                            }
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "PageComponent",
                                className: "page_component",
                                container: true,
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        xs: 12,
                                        className: "incentive_catalog"
                                    },
                                    uiComponent: this.inputs.component
                                }
                            ]
                        },
                    ]
                }
            ]
        })
    }
}

export default IncentiveChangelogTab;