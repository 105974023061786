export const {
  REACT_APP_OAUTH_COGNITO_URL,
  REACT_APP_OAUTH_CLIENT_ID,
  REACT_APP_OAUTH_REDIRECT_URI,
  REACT_APP_OAUTH_GRANT_TYPE,
  REACT_APP_OAUTH_IDENTITY_PROVIDER,
  REACT_APP_OAUTH_RESPONSE_TYPE,
  REACT_APP_OAUTH_SCOPE,
  REACT_APP_UNIFIED_API_BASE_URL,
  REACT_APP_UNIFIED_API_TOKEN,
} = process.env as { [key: string]: string };
