import {
  REACT_APP_OAUTH_COGNITO_URL,
  REACT_APP_OAUTH_CLIENT_ID,
  REACT_APP_OAUTH_REDIRECT_URI,
  REACT_APP_OAUTH_GRANT_TYPE,
  REACT_APP_OAUTH_IDENTITY_PROVIDER,
  REACT_APP_OAUTH_RESPONSE_TYPE,
  REACT_APP_OAUTH_SCOPE,
} from "./env";
import { AuthOauth2GrantsConfigBuilder } from "../components/AwsAmplifyHelper/AuthOauth2Grants";

export const federatedOauth = new AuthOauth2GrantsConfigBuilder()
  .withCognitoUrl(REACT_APP_OAUTH_COGNITO_URL)
  .withClientId(REACT_APP_OAUTH_CLIENT_ID)
  .withRedirectUri(REACT_APP_OAUTH_REDIRECT_URI)
  .withGrantType(REACT_APP_OAUTH_GRANT_TYPE)
  .withIdentityProvider(REACT_APP_OAUTH_IDENTITY_PROVIDER)
  .withResponseType(REACT_APP_OAUTH_RESPONSE_TYPE)
  .withScope(REACT_APP_OAUTH_SCOPE)
  .build();
