import { Typography, Button, Grid } from "@material-ui/core"


export const PrintModalMetadataBuilder = () => (new _PrintModalMetadataBuilder());
class _PrintModalMetadataBuilder {
    constructor() {
        this.inputs = {
            onClickFunction: (value) => {},
            currentTabName: "",
            currentTabNumber: "",

        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `has${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this;
            }
        })
    }

    build() {
        return ({
            uiComponent: Grid,
            props: {
                id: "PrintModalMain",
                className: "PrintModalMain",
                container: true,
            },
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "printTab",
                        className: "printTab",
                        container: true,

                    },
                    elements: [
                        {
                            size: 12,
                            uiComponent: Typography,
                            props: {
                                text: "WHAT INFORMATION WOULD YOU LIKE TO PRINT?",
                                className: "printHeadline",
                                children: "WHAT INFORMATION WOULD YOU LIKE TO PRINT?",
                            }
                        },
                        {

                            size: 6,
                            uiComponent: Button,
                            props: {
                                name: "PrintTabOnly",
                                className: "PrintModalSubmit",
                                children: `Print ${this.inputs.currentTabName} Information Only`,
                                onClick: () => {this.inputs.onClickFunction(this.inputs.currentTabNumber)}
                            }
                        },
                    ]
                },
                {
                    uiComponent: Grid,
                    props: {
                        id: "printAll",
                        className: "print_all",
                        container: true,

                    },
                    elements: [
                        {

                            size: 6,
                            uiComponent: Button,
                            props: {
                                name: "PrintAll",
                                className: "PrintModalSubmit",
                                children: "Print All Incentive Information",
                                onClick: () => {this.inputs.onClickFunction(null)}
                            }
                        },
                    ],
                }]
        }
        )
    }
}

