import React from 'react'
import { Paper, Typography, Box, Grid } from "@material-ui/core";

const TooltipMetadata = () => (new _TooltipMetadata());

export class _TooltipMetadata {

    constructor() {
        this.inputs = {
            infoArray: [],
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `is${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        return ({
            sections: [
                {
                    uiComponent: Paper,
                    props: {
                        id: "tooltip_container",
                        className: "tooltip_container",
                        container: true,
                    },
                    elements: [
                        {
                            uiComponent: InfoWrapper,
                                props: {
                                className: "info-wrapper",
                                    children: createInternalGridItems(this.inputs.infoArray)
                        
                            }
                        }
                    ]
                }
            ]
        })
    }
}

const InfoWrapper = ({ title, titleProps, children }) => {
    titleProps = (titleProps) ? titleProps : {};
    titleProps['children'] = title;
    return (
        <React.Fragment>
             <Box className="detail_container" id="box_needed">
                {React.createElement(Typography, titleProps)}
            </Box>
            <Box className="value">
                {children}
            </Box>
        </React.Fragment>

    )
}

const createInternalGridItems = (properties) => {
    return (
        <Grid xs={12} container className="details-container">
            {properties.map(prop => (
                <Grid className="details" >
                    <InfoWrapper title={prop.label} className="value">{prop.value}</InfoWrapper>
                </Grid>
            ))
            }
        </Grid>
    )

}
export default TooltipMetadata
