import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  REACT_APP_UNIFIED_API_TOKEN,
  REACT_APP_UNIFIED_API_BASE_URL,
} from "../constants/env";

export const unifiedApi = createApi({
  baseQuery: fetchBaseQuery({
    reducerPath: "unifiedAPI",
    baseUrl: REACT_APP_UNIFIED_API_BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("authorization", `Bearer ${REACT_APP_UNIFIED_API_TOKEN}`);
      return headers;
    },
  }),
  endpoints: (build) => {
    return {
      getGMCommercialIncentives: build.query({
        query: ({ postcode, form_factor }) => ({
          url: `commercial/incentives?postcode=${postcode}&form_factor=${form_factor}`,
        }),
      }),
      getCommercialIncentives: build.query({
        query: ({ postcode }) => ({
          url: "commercial/incentives",
          params: { postcode },
        }),
      }),
      getCommercialIncentive: build.query({
        query: ({ handle }) => `commercial/incentives/${handle}`,
      }),
      getChargers: build.query({
        query: (name) => `commercial/chargers?${name}`,
      }),
      getVendors: build.query({
        query: () => "commercial/vendors",
      }),
      getUtilities: build.query({
        query: ({ postcode }) => `powersuppliers?postcode=${postcode}`,
      }),
      getVehicles: build.query({
        query: ({ postcode }) => `vehicles?postcode=${postcode}`,
      }),
      getVehicle: build.query({
        query: ({ handle, postcode }) =>
          `vehicles/${handle}?postcode=${postcode}`,
      }),
      getCommercialVehicle: build.query({
        query: ({ handle, postcode }) =>
          `commercial/vehicles/${handle}?postcode=${postcode}`,
      }),
      getCommercialVehicles: build.query({
        query: ({ postcode }) => `commercial/vehicles?postcode=${postcode}`,
      }),
    };
  },
});

export const {
  useGetChargersQuery,
  useGetVendorsQuery,
  useGetUtilitiesQuery,
  useGetVehiclesQuery,
  useGetVehicleQuery,
  useGetCommercialVehicleQuery,
  useGetCommercialVehiclesQuery,
  useGetCommercialIncentivesQuery,
  useGetCommercialIncentiveQuery,
  useGetGMCommercialIncentivesQuery,
} = unifiedApi;
