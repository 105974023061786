import React from "react";
import { Paper, Typography, Grid, Button } from "@material-ui/core";
import GM_homepage from "../assets/images/GM_homepage.jpg";
import zaLogo from "../assets/images/zrLogo.png";
import { useFederatedUserAuthUrl } from "../components/AwsAmplifyHelper/FederatedUser";

import GM_Blue_Horizontal_RGB from "../assets/GMLogosSVG/GM_Blue_Horizontal_RGB.svg";
const GM_Home = () => new _GM_Home();

export class _GM_Home {
  constructor() {
    this.inputs = {
      homepageImage: "",
      title: "",
      buttonText: "",
    };

    //dynamically generating setters for inputs in constructor
    Object.keys(this.inputs).forEach((input) => {
      // if booleanValue, it will have the property name became has
      let functionNameValue =
        typeof this.inputs[input] == "boolean"
          ? `is${input.charAt(0).toUpperCase() + input.slice(1)}`
          : `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

      this[`${functionNameValue}`] = (value) => {
        this.inputs[`${input}`] = value;
        return this;
      };
    });
  }

  build() {
    return {
      sections: [
        {
          uiComponent: Grid,
          props: {
            id: "GmHomeImage",
            className: "GmHomeImage",
          },
          elements: [
            {
              gridItemProps: {
                item: true,
                xs: 12,
              },
              uiComponent: Typography,
              props: {
                className: "zappyride_logo",
                children: ["powered by  ", <img src={zaLogo} alt="ZappyRide" id="logo" />],
              },
            },
            {
              gridItemProps: {
                item: true,
                xs: 12,
              },
              uiComponent: LoginUI,
              props: {
                className: "LoginContainer",
                title: this.inputs.title,
                buttonText: this.inputs.buttonText,
              },
            },
            {
              uiComponent: React.Fragment,
              props: {
                className: "GmHomeImage",
                children: (
                  <img
                    src={GM_homepage}
                    alt="two cars parked on desert sand"
                    className="gm-hero-image"
                  />
                ),
              },
            },
          ],
        },
      ],
    };
  }
}

export default GM_Home;

const LoginUI = ({ title, buttonText }) => {
  const authUrl = useFederatedUserAuthUrl();

  return (
    <Paper className="LoginContainer">
      <img
        src={GM_Blue_Horizontal_RGB}
        alt="GM fleets logo"
        className="GMLogo"
      />
      <Typography>{title}</Typography>
      <Button id="LoginButton" onClick={() => window.open(authUrl, "_self")}>
        {buttonText}
      </Button>
    </Paper>
  );
};
