import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  grant: undefined,
  currentAuthCode: undefined,
  implicitGrant: undefined,
  clientCredentialsGrant: undefined,
  Session: undefined,
  authenticationFlowType: undefined,
  challengeName: undefined,
  challengeParam: undefined,
  client: undefined,
  keyPrefix: undefined,
  pool: undefined,
  signInUserSession: undefined,
  storage: undefined,
  userDataKey: undefined,
  username: undefined,
  CognitoUserPool: undefined,
  Storage: undefined,
  accessToken: undefined,
  expires_in: undefined,
  idToken: undefined,
  refreshToken: undefined,
  tokenType: undefined
}

const stateKeys = Object.keys(initialState);

const stateRetrievers = {};
const reducers = {};

stateKeys.forEach((key) => {
  stateRetrievers[key] = (state) => {
    if(!state.userAuthentication) {
      return null;
    }
    return state.userAuthentication[key];
  }

  reducers[key] = (state, action) => {
    state[key] = action.payload;
  }
});

export const userAuthenticationSlice = createSlice({
  name: "userAuthentication",
  initialState,
  reducers
});

export const updaters = userAuthenticationSlice.actions;

export const retrievers = stateRetrievers;

export default userAuthenticationSlice.reducer;
