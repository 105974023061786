import largeLuxurySUV from '../assets/images/Tool Tip/largeLuxurySUV.png'
import largePickup from '../assets/images/Tool Tip/largePickup.png'
import smallMVP from '../assets/images/Tool Tip/smallMVP.png'
import smallSUV from '../assets/images/Tool Tip/smallSUV.png'
import midsizeSUV from '../assets/icons/cadillac-lyriq.png'
import cargoTruck from '../assets/icons/brightdrop.png'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { red, yellow, green } from '@material-ui/core/colors';

const TOOLTIPS = {

    statusTooltip: [{
        label: [<FiberManualRecordIcon style={{ color: green[500] }} />, "open"],
        value: "Actively accepting applications."
    },
    {
        label: [<FiberManualRecordIcon style={{ color: yellow[500] }} />, "haitus"],
        value: "Applications are being accepted, but not processed. Processing will resume at some point in the future."
    },
    {
        label: [<FiberManualRecordIcon style={{ color: yellow[500] }} />, "waitlist"],
        value: "Not currently being accepting new applications, but are expected to resume upon additional funding. "
    },
    {
        label: [<FiberManualRecordIcon style={{ color: red[500] }} />, "closed"],
        value: "No longer accepting new applications. Not expected to receive additional funding.  For reference purposes only."
    }],


    organizationTypeTooltip: [{
        label: "organization type",
        value: "Incentives are frequently limited to certain organization types."

    },
    {
        value: "Select your customer’s organization type to ensure only applicable incentives are displayed"


    }],
    equipmentTooltip: [{
        label: "vehicles",
        value: "Incentives applicable to vehicles"
    },
    {
        label: "chargers",
        value: "Incentives applicable to chargers (EVSEs), charger infrastructure, and electricity rate discounts."

    }],
    vehicleSegmentTooltip: [
        {
            label: [<img src={smallSUV} id="vehicleIcon" alt="car" />, <span id="icon_class">Small MPV</span>]
        },
        {
            label: [<img src={smallMVP} id="vehicleIcon" alt="car" />, <span id="icon_class">Small SUV</span>]
        },
        {
            label: [<img src={midsizeSUV} id="vehicleIcon" alt="car" />, <span id="icon_class">Midsize SUV</span>]
        },
        {
            label: [<img src={largePickup} id="vehicleIcon" alt="car" />, <span id="icon_class">Large Pickup</span>]
        },
        {
            label: [<img src={largeLuxurySUV} id="vehicleIcon" alt="car" />, <span id="icon_class">Large Luxury SUV</span>]
        },
        {
            label: [<img src={cargoTruck} id="vehicleIcon" alt="car" />, <span id="icon_class">Cargo Van</span>]
        },
    ],
    locationTooltip: [{
        label: "eligibility",
        value: "Show incentives applicable based on the fleet's location"
    },
    {
        label: "optional",
        value:
            "Location may be left blank to show all incentives, regardless of location.",
    },
    {
        value: "This can help customers with multiple locations to identify which locations may receive the greatest monetary incentives."
    }]
}



export default TOOLTIPS