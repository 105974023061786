import React from 'react';
import { Grid, Paper } from "@material-ui/core"
import { Form } from 'react-final-form';

export { PrintModalMetadataBuilder } from './PrintModalFormMetadata';

const RenderPrintForm = (
    {
        className,
        componentMetadata,
        onSuccess = () => { },
        renderFunction = null,
    }
) => {

    const onSubmit = async values => {
        componentMetadata.onSubmit(values);
        onSuccess();
    };

    const renderSections = (sections) => {
        const renderedSection = sections.map(item => {
            const elements = [];

            if ('sections' in item) elements.push(renderSections(item.sections));
            else if ('elements' in item) {
                item.elements.forEach((element, index) => {
                    elements.push(renderElement(element, index))
                });
            }

            item.props["children"] = elements;

            return React.createElement(
                item.uiComponent, item.props);
        });

        return renderedSection;
    }

    const renderElement = (element, idx) => {

        return (
            <Grid item xs={element.size} key={idx}>
                {React.createElement(element.uiComponent, element.props)}
            </Grid>
        );
    }

    const defaultRenderFunction = (reactArrayofSections) => {
        return (
            <div className={className} style={{padding: 20, margin: 'auto', maxWidth: 600 }}>
                <Form
                    onSubmit={onSubmit}
                    initialValues={componentMetadata.initialValues}
                    validate={componentMetadata.valdationFunction}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit} >
                            <Paper>
                                {reactArrayofSections}
                            </Paper>
                        </form>
                    )}
                />
            </div>
        )
    }

    const doRender = renderFunction || defaultRenderFunction;
    return doRender(renderSections(componentMetadata.sections));
}



export default RenderPrintForm;