import { createSlice } from "@reduxjs/toolkit";
import { formFactorsList, SUV } from "../constants/formFactors";
import { ALL } from "../constants/labels";

export const INCENTIVE_FOCUS = "incentive_focus";
export const IS_ELIGIBLE_TO_SPECIFIED_FORM_FACTORS_ONLY =
  "is_eligible_to_specified_form_factors_only";

const initialState = {
  postcode: "95206",
  available: true,
  current_funding: null,
  current_funding_status: "",
  current_program_status: "current",
  eligibility_assumptions: "",
  eligible_equipment: "",
  eligible_charging_requirements: "",
  eligible_charging_technology: "",
  eligible_charging_technology_description: null,
  eligible_fuel_types: "",
  eligible_organization_classification_description: "",
  eligible_organization_other_description: null,
  eligible_organizations: ALL,
  eligible_project_accessory: "",
  eligible_project_type_description: "",
  eligible_project_types: "",
  eligible_scrappage_requirement: false,
  eligible_scrappage_requirement_description: null,
  eligible_vehicle_duties: "",
  eligible_vehicle_type: "",
  eligible_vehicle_type_description: "",
  eligible_vehicle_weight_classes: "",
  funding_cap_per_applicant: "",
  funding_cap_per_project: "",
  funding_situation_description: "",
  grantor_name: "",
  grantor_type: "",
  incentive_application_url: null,
  incentive_description: "",
  [INCENTIVE_FOCUS]: ALL,
  incentive_handle: "",
  incentive_name: "",
  incentive_nature: "",
  incentive_program_contact_info: null,
  incentive_scope: "",
  incentive_scope_description: "",
  incentive_scope_other: null,
  incentive_url: "",
  [IS_ELIGIBLE_TO_SPECIFIED_FORM_FACTORS_ONLY]: ALL,
  is_lease_eligible: true,
  form_factor: formFactorsList.join(","),
  lease_beneficiary: "",
  mutually_exclusive_programs: "",
  total_funding: null,
  typical_amount: "",
  typical_amount_description: "",
};

const stateKeys = Object.keys(initialState);

const stateRetrievers = {};
const reducers = {};

stateKeys.forEach((key) => {
  stateRetrievers[key] = (state) => {
    if (!state.CommercialIncentive) {
      return null;
    }
    return state.CommercialIncentive[key];
  };
  reducers[key] = (state, action) => {
    state[key] = action.payload;
  };
});

export const CommercialIncentiveSlice = createSlice({
  name: "CommercialIncentive",
  initialState,
  reducers,
});

export const updaters = CommercialIncentiveSlice.actions;

export const retrievers = stateRetrievers;

export const valueHasAllFormFactors = (value) => {
  const split = value.split(",");

  if (value === ALL) {
    return true;
  } else if (split.length < 2) {
    return false;
  }

  return split.every((formFactor) => formFactorsList.includes(formFactor));
};

export const getFilterableFormFactorsValue = (value) =>
  valueHasAllFormFactors(value) ? ALL : value.includes(SUV) ? SUV : value;

export default CommercialIncentiveSlice.reducer;
