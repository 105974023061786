import { Grid, Typography } from '@material-ui/core';

const IncentiveEligibilityTab = () => (new _IncentiveEligibilityTab());
class _IncentiveEligibilityTab {
    constructor() {
        this.inputs = {
            totalFunding: 0,
            currentFunding: 0,
            fundingSituationDescription: "",
            methodologyDescription: "",
            fundingCapPerProject: "",
            fundingCapPerApplicant: "",
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `is${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        return ({
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "FundingTabPage",
                        className: "FundingTabPage",
                        container: true,
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                item: true,
                                xs: 12,
                                id: "FundingTabSectionTop",
                                container: true
                            },
                            sections: [
                                {
                                    uiComponent: Grid,
                                    props: {
                                        id: "FundingTabSectionTopLeft",
                                        container: true
                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,

                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "FUNDING DETAILS",
                                                className: "mainTitle"
                                            }
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,

                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Total Funding",
                                                className: "mainsubTitle",
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,

                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.totalFunding ? '$' + this.inputs.totalFunding.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A",

                                                className: "mainText"
                                            },

                                        }
                                    ]
                                },
                                {
                                    uiComponent: Grid,
                                    props: {
                                        id: "FundingTabSectionTopRight",
                                        container: true
                                    },
                                    elements: [
                                        {
                                            gridItemProps: {
                                                item: true,

                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: "Current Funding",
                                                className: "mainsubTitle"
                                            },
                                        },
                                        {
                                            gridItemProps: {
                                                item: true,

                                            },
                                            uiComponent: Typography,
                                            props: {
                                                children: this.inputs.currentFunding ? '$' + this.inputs.currentFunding.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A",
                                                className: "mainText"
                                            },

                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "FundingTabSectionTwo",
                                item: true,
                                xs: 12
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Funding Situation Description",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.fundingSituationDescription ? this.inputs.fundingSituationDescription : "N/A",
                                        className: "mainText"
                                    }
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "FundingTabSectionThree",
                                item: true,
                                xs: 12,
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Methodology Description",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.methodologyDescription ? this.inputs.methodologyDescription : "N/A",
                                        className: "mainText"
                                    }
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "FundingTabSectionFour",
                                item: true,
                                xs: 12
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Funding Cap per Project",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.fundingCapPerProject ? this.inputs.fundingCapPerProject === "Unspecified" ? this.inputs.fundingCapPerProject : '$' + this.inputs.fundingCapPerProject.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A",
                                        className: "mainText"
                                    }
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "FundingTabSectionBottom",
                                item: true,
                                xs: 12
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Funding Cap per Applicant",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.fundingCapPerApplicant ? this.inputs.fundingCapPerApplicant.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A",
                                        className: "mainText"
                                    }
                                },
                            ]
                        },
                    ]
                }
            ]
        })
    }
}


export default IncentiveEligibilityTab;
