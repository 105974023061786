import React from "react";
import { Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { red, yellow, green } from "@material-ui/core/colors";
import { EligibleEquipmentCell } from "./EligibleEquipmentCell";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { CellWifiSharp } from "@material-ui/icons";

const moreDetailsLinkText = "More Details >";

const calcDaysLeft = (expiration) => {
  if (typeof expiration == "string" && expiration !== "2038-01-01") {
    const diffTime = Math.abs(
      new Date(expiration.replace("-", "/")) - new Date()
    );
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 14) {
      return diffDays + "d left ";
    }
    if (diffDays > 14 && diffDays < 60) {
      return (diffDays = `${Math.floor(diffDays / 7)}w left`);
    }
    if (diffDays > 60 && diffDays < 730) {
      return (diffDays = `${Math.floor(diffDays / 30)}m left`);
    }
    if (diffDays > 365) {
      return (diffDays = `${Math.floor(diffDays / 365)}y left`);
    }
  } else {
    return "";
  }
};

const colors = {
  open: green,
  hiatus: yellow,
  waitlist: yellow,
  closed: red,
};

const FormatedStatusComponent = ({ cellValues }) => {
  let programStatus =
    cellValues.value === "current" ? "open" : cellValues.value;
  if(cellValues.row.current_program_status === "haitus") {
    programStatus = "haitus"
  } else if(cellValues.row.current_program_status === "waitlist") {
    programStatus = "waitlist"
  } else if(cellValues.row.current_program_status === "upcoming") {
    programStatus = "waitlist"
  }
  let color = colors[programStatus];
  programStatus =
    programStatus.charAt(0).toUpperCase() + programStatus.slice(1);

  return (
    <React.Fragment>
      <div className="status_display">
        <FiberManualRecordIcon style={{ color: color[500] }} />
        <div className="daysLeftFlex">
          <Typography>{programStatus}</Typography>
          <Typography
            className={
              calcDaysLeft(cellValues.row.expiration_date).includes("d")
                ? "red"
                : null
            }
          >
            {calcDaysLeft(cellValues.row.expiration_date)}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

const FormatedMobileNameComponent = ({ cellValues }) => {
  return (
    <div className="mobile_incentive_name">
      <div className="status_display">
        <NavLink
          to={`/incentive/${cellValues.row.id}`}
          id="more_details_link_mobile"
        >
          {`${cellValues.row.incentive_name}`}
        </NavLink>
      </div>
    </div>
  );
};

const CatalogGrid = ({
  indexes,
  data,
  sortFunction,
  itemsFoundText,
  idField,
  postcode,
}) => {
  const theme = useTheme();
  const iSmBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdBreakPoint = useMediaQuery(theme.breakpoints.only("md"));
  const isLgBreakPoint = useMediaQuery(theme.breakpoints.up("lg"));

  const breakPoints = {
    sm: iSmBreakPoint,
    md: isMdBreakPoint,
    lg: isLgBreakPoint,
  };
  const activeBreakpoints = Object.entries(breakPoints)
    .filter(([key, value]) => {
      return value === true;
    })
    .map(([key]) => key);

  let catalogItems = React.useMemo(
    () =>
      indexes.map((index) =>
        Object.assign({ id: data[index][idField] }, data[index])
      ),
    [indexes, data, idField]
  );

  const [pageSize, setPageSize] = React.useState(10);

  if (sortFunction) {
    catalogItems = catalogItems.sort(sortFunction);
  }

  return (
    <React.Fragment>
      <div className="catalog" id="incentive_catalog">
        <div className="data_grid">
          <DataGrid
            id="data_grid-table"
            components={{
              ColumnsPanel: FormatedStatusComponent,
              NoRowsOverlay: () => <NoDataOverlay postcode={postcode} />,
            }}
            width={200}
            rows={catalogItems}
            columns={getColumnsByBreakpoint(allColumns, activeBreakpoints)}
            rowHeight={50}
            headerHeight={45}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </div>
        <Typography className="total_found">
          {indexes.length}
          {itemsFoundText}{" "}
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default CatalogGrid;

const sm = "sm";
const md = "md";
const lg = "lg";

const allColumns = [
  {
    keys: [sm, md, lg],
    field: "current_program_status",
    headerName: "Status",
    width: 105,
    editable: true,
    renderCell: (cellValues) => (
      <FormatedStatusComponent cellValues={cellValues} />
    ),
  },
  {
    keys: [md, lg],
    field: "incentive_name",
    headerName: "Name",
    width: 270,
  },
  {
    keys: [sm],
    field: "incentive_name",
    headerName: "Name",
    width: 250,
    renderCell: (cellValues) => (
      <FormatedMobileNameComponent cellValues={cellValues} />
    ),
  },
  {
    keys: [sm, md, lg],
    field: "typical_amount",
    headerName: "Value",
    width: 180,
  },
  {
    keys: [lg],
    field: "incentive_focus",
    headerName: "Eligible Equipment",
    width: 240,
    renderCell: (cellValues) => <EligibleEquipmentCell row={cellValues.row} />,
  },
  {
    keys: [sm, md, lg],
    field: "grantor_name",
    headerName: "Grantor",
    width: 170,
  },
  {
    keys: [md, lg],
    field: "incentive_url",
    headerName: " ",
    width: 110,
    renderCell: (cellValues) => {
      return (
        <NavLink to={`/incentive/${cellValues.row.id}`} id="more_details_link">
          {moreDetailsLinkText}
        </NavLink>
      );
    },
  },
];

const byBreakpoints = (breakpoints) => (item) => {
  return item.keys.some((key) => breakpoints.includes(key));
};

const getColumnsByBreakpoint = (columns, breakpoints) => {
  return columns.filter(byBreakpoints(breakpoints));
};

const noDataText = "Search for incentives using the filters above";
const NoDataOverlay = ({ postcode = null }) => {
  return (
    <div className="no_data_text">
      {postcode
        ? `No state or local incentives apply to this zip code (${postcode})`
        : noDataText}
    </div>
  );
};
