import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    postcode: "95206",
    currentTab: 0,
    isComingFromPreviousPage: false
}

const stateKeys = Object.keys(initialState);

const stateRetrievers = {};
const reducers = {};

stateKeys.forEach((key) => {
    stateRetrievers[key] = (state) => {
        if (!state.IncentivePageNavigation) {
            return null;
        }
        return state.IncentivePageNavigation[key];
    }
    reducers[key] = (state, action) => {
        state[key] = action.payload;

    }
});

export const IncentivePageNavigationSlice = createSlice({
    name: "IncentivePageNavigation",
    initialState,
    reducers
});

export const updaters = IncentivePageNavigationSlice.actions;

export const retrievers = stateRetrievers;

export default IncentivePageNavigationSlice.reducer;
