import React, { useEffect } from "react";
import GM_Home from "../../ComponentMetadata/HomePage";
import GenericMetadataParser from "../GenericMetadataParser";
import { useOAuthGrantFromTokenMutation } from "../AwsAmplifyHelper/CognitoService";
import { useFederatedUserAuthUrl } from "../AwsAmplifyHelper/FederatedUser";
import { useDispatch } from "react-redux";
import { updaters } from "../AwsAmplifyHelper/userAuthenticationSlice";

import { Redirect } from "react-router";
import Loaders from "../Loaders";

const GM_HomePage = () => {
  const dispatch = useDispatch();
  const federatedUserAuthUrl = useFederatedUserAuthUrl();
  const params = new URLSearchParams(document.location.search);
  const [validateToken, { data, error, isLoading }] =
    useOAuthGrantFromTokenMutation();

  if (localStorage.getItem("priorAuthentication") == null) {
    localStorage.setItem("priorAuthentication", "false");
  }

  if (
    !params.get("code") &&
    localStorage.getItem("priorAuthentication") === "true"
  ) {
    window.open(federatedUserAuthUrl, "_self");
  }

  if (data) {
    localStorage.setItem("priorAuthentication", "true");
    dispatch(updaters.grant(data));
  }

  useEffect(() => {
    if (params.get("code")) {
      validateToken(params.get("code"));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loaders type="circle_bounce">Loading</Loaders>
      ) : data ? (
        <Redirect to={"/incentives"} />
      ) : error || localStorage.getItem("priorAuthentication") === "false" ? (
        <GenericMetadataParser
          className="GmHomeImage"
          componentMetadata={GM_Home()
            .withTitle("Electric Vehicle Incentives")
            .withButtonText("Continue With GM")
            .build()}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default GM_HomePage;
