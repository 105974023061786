import React, { useState } from 'react';
import GenericMetadataParser from '../GenericMetadataParser';
import { filterValues } from '../catalog/catalogUtils'
import MerchantsVehicleCatalogCard from '../catalog/VehicleCatalogCard';
import { Box, Slider, Typography, Checkbox, FormControl, Select, useMediaQuery, useTheme, Grid } from '@material-ui/core';
import { useGetVehiclesQuery } from '../../services/unifiedAPIQuery';
import CatalogToggleButtonFilterCollection from '../catalog/CatalogToggleButtonFilterCollection';
import Catalog from "../catalog/Catalog";
import SimpleModal from "../modal/SimpleModal"
import ArrowDropdownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ContactFormModal from "../modal/ContactForm/ContactFormModal";
import EmailIcon from '@material-ui/icons/Email';
import { MuiThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import CatalogPageMetadata from '../../ComponentMetadata/CatalogPageMetadata';

const CustomRangeSliderLabel = ({ value, children }) => {
    return (
        <>
            {children}
            <Box className="sliderValue">  <Typography className="description"><span className="value"> {value} miles </span></Typography></Box>
        </>
    )
}

const CustomPriceSliderLabel = ({ value, children }) => {
    return (
        <>
            {children}
            <Box className="sliderValue">  <Typography className="description"><span className="value"> ${value} max </span></Typography></Box>
        </>
    )
}

const muiTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "white",
            },
            track: {
                color: '#7cb554',
            },
            rail: {
                color: '#8f908f',
            },
        },

    }
});

const MerchantsSlider = ({ title, valueText, current, marks = [], min = 0, max, valueLabelComponent, defaultValue, isMobileDevice, onChange = () => { }, onChangeCommitted = () => { } }) => {

    return (
        <Box className="catalogSlider">
            <div className="sliderTitle">
                <Typography gutterBottom>
                    {isMobileDevice ? null : title}
                </Typography>
                <Typography>
                    {/* {valueText(defaultValue)} */}
                </Typography>
            </div>


            <MuiThemeProvider theme={muiTheme}>
                <Slider min={min} max={max} defaultValue={defaultValue}
                    marks={[{ value: min, label: min }, { value: max, label: max }]}
                    ValueLabelComponent={valueLabelComponent}
                    // valueLabelDisplay="auto"
                    onChange={(event, newValue) => {
                        if (!event) return;
                        onChange(newValue)
                    }}
                    onChangeCommitted={(event, newValue) => {
                        if (!event) return;
                        onChangeCommitted(newValue)
                    }}
                />
            </MuiThemeProvider>
        </Box>
    )
}


const vehicleTypeFilter = new Map([
    ['Coupe', ['Coupe']],
    ['Sedan', ['Sedan']],
    ['Hatchback', ['Hatchback']],
    ['SUV', ['SUV']],
    ['Station Wagon', ['Station Wagon']],
    ['Minivan', ['Minivan']],
    ['Van', ['step_van']],
    ['Truck', ['trailer_truck', 'pickup_truck', 'box_truck', 'utility_truck']],
    ['Bus', ['transit_bus', 'school_bus', 'shuttle_bus']],
    ['Motor Coach', ['motor_coach']],
    ['TRU', ['tru']],
    ['Forklift', ['forklift']],
    ['Tractor', ['terminal_tractor']],
    ['Chasis', ['cutaway_chassis']],
    ['Speciality', ['speciality']],
    ['Bev Drive', ['bev_drive_system']]
])

const fuelTypes = new Map([
    ["All Electric", ['BEV']],
    ["Plug-in Hybrid", ['PHEV']],
])

const sortFunctionsMap = new Map([
    ["Price: Low to High", (v1, v2) => (v2.msrp < v1.msrp) ? 1 : -1],
    ["Price: High to Low", (v1, v2) => (v2.msrp < v1.msrp) ? -1 : 1],
    ["Range: Low to High", (v1, v2) => (v2.electric_range < v1.electric_range) ? 1 : -1],
    ["Range: High to Low", (v1, v2) => (v2.electric_range < v1.electric_range) ? -1 : 1],
    // ["Availability", (v1,v2) => (!v1.available_for_purchase_as_new && v2.available_for_purchase_as_new) ? -1 : 1]
])

const vehicleTypeSelection = new Set();
const fuelTypeSelection = new Set();
const activeFuelTypeButtons = new Set();
const activeVehicleTypeButon = new Set();

const VehicleCatalogPage = () => {

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'));


    const { data, error, isLoading, isUninitialized } = useGetVehiclesQuery(
        { postcode: '90210' },
    )
    let [inputFuel, setInputFuel] = useState([]);
    let [inputAvaiableNow, setinputAvaiableNow] = useState(true);
    let [inputRange, setInputRange] = useState({ lessThan: 300, greaterThan: 0 });
    let [inputPrice, setInputPrice] = useState({ lessThan: 99999, greaterThan: 0 });
    let [inputFormFactor, setInputFormFactor] = useState([]);
    let [sort, setSort] = useState("Range: High to Low")

    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const milesValueText = (value) => {
        return `${value} miles`
    }
    const priceValueText = (value) => {
        return `$${value} max`
    }

    const buttonFilters = <CatalogToggleButtonFilterCollection
        title={isMobileDevice ? "" : "Type"}
        isMobile={isMobileDevice}
        filters={vehicleTypeFilter}
        filterTypeSelection={vehicleTypeSelection}
        setInput={setInputFormFactor}
        activeButtonSet={activeFuelTypeButtons} />

    const fuelTypeButtonFilters = <CatalogToggleButtonFilterCollection
        title={isMobileDevice ? "" : "Fuel Type"}
        isMobile={isMobileDevice}
        filters={fuelTypes}
        filterTypeSelection={fuelTypeSelection}
        setInput={setInputFuel}
        activeButtonSet={activeVehicleTypeButon} />


    const vehicleFilterFieldNames = ["fuel", "electric_range", "msrp", "form_factor", "available_for_purchase_as_new"]
    const vehicleFilterFieldNameToValueMapping = [inputFuel, inputRange, inputPrice, inputFormFactor, inputAvaiableNow]

    const inputRangeSlider = <MerchantsSlider title="Range" valueText={milesValueText} valueLabelComponent={CustomRangeSliderLabel} min={0} max={900} defaultValue={inputRange.lessThan} isMobileDevice={isMobileDevice} onChangeCommitted={(newValue) => setInputRange({ lessThan: newValue, greaterThan: 0 })} />
    const inputPriceSlider = <MerchantsSlider title="Price" valueText={priceValueText} valueLabelComponent={CustomPriceSliderLabel} min={0} max={200000} valueLabelDisplay="on" marks={[{ value: 0, label: '0' }, { value: 200000, label: '200000' }]} defaultValue={inputPrice.lessThan} isMobileDevice={isMobileDevice} onChangeCommitted={(newValue) => setInputPrice({ lessThan: newValue, greaterThan: 0 })} />

    const LeftHandFilters = (props) => {

        let [dropdownOpen, setDropdownOpen] = useState({ "Range": false, "Type": false, "Price": false, "Fuel Type": false })

        const handleClick = (e) => {
            if (e === "Show Results") {
                handleClose();
                return
            }
            const dropdown = e.target.innerText
            setDropdownOpen({ ...dropdownOpen, [dropdown]: !dropdownOpen[dropdown] })

        }

        const arrow = (label) => (
            <Box className="filterDropdown" onClick={(e) => handleClick(e)}>
                <Typography>
                    {label}
                </Typography>
                {dropdownOpen[label] ? <ArrowDropUpIcon /> : <ArrowDropdownIcon />}
            </Box>
        )

        const CustomColorCheckbox = withStyles({
            root: {
                color: "#fff",
                "&$checked": {
                    color: "#7cb554"
                }
            },
            checked: {}
        })((props) => <Checkbox color="default" {...props} />);

        return (
            <div id={props.platform === "mobile" ? "leftHandFiltersMobile" : "leftHandFiltersDesktop"}>
                <Box className={props.platform === "mobile" ? "filterMobile" : "filterDesktop"}>
                    <Typography>Filter</Typography>
                    {props.platform === "mobile" ?
                        <Typography className="showResultsTitle" onClick={() => handleClick("Show Results")}>Show Results</Typography>
                        : null}
                </Box>
                <Box id="leftHandFilters">
                    {props.platform === "mobile" ?
                        <>
                            {arrow("Range")}
                            {dropdownOpen.Range && inputRangeSlider}
                            {arrow("Type")}
                            {dropdownOpen.Type && buttonFilters}
                            {arrow("Price")}
                            {dropdownOpen.Price && inputPriceSlider}
                            {arrow("Fuel Type")}
                            {dropdownOpen["Fuel Type"] && fuelTypeButtonFilters}
                        </>
                        :
                        <>
                            {inputRangeSlider}
                            {buttonFilters}
                            {inputPriceSlider}
                            {fuelTypeButtonFilters}
                        </>
                    }
                    <Box className={props.platform === "mobile" ? "availableMobile" : "availableDesktop"}>
                        <Typography className="availableTitle"> Available Now? </Typography>
                        <CustomColorCheckbox className="availableCheckbox" checked={inputAvaiableNow} onChange={(event, value) => { setinputAvaiableNow(value) }} />
                    </Box>
                </Box>
            </div>
        )
    }


    const vehicleCardRenderer = (vehicle) => {

        return (
            MerchantsVehicleCatalogCard()
                .withCardMediaTitle(vehicle.handle)
                .withTitle(vehicle.display_name)
                .withSubType((vehicle.trim))
                .withImageUrl(vehicle.images[0].url_full)
                .withElectricRange(vehicle.electric_range)
                .withVehicleType((vehicle.form_factor).replace("_", " "))
                .withTotalRange(vehicle.total_range)
                .withBatteryCapacity(vehicle.battery_capacity)
                .withVehicleHandle(vehicle.handle)
                .withMsrp(vehicle.msrp)
                .build()
        )
    }

    const catalogSort = (
        <React.Fragment>
            <Box className={isMobileDevice ? `mobile_catalog_sort` : `catalog_sort`}>
                <Typography>Sort By:</Typography>
                <FormControl variant="outlined" className={""}>
                    <Select
                        className={"sort_by_select"}
                        native
                        value={sort}
                        onChange={(event) => {
                            if (!event) return;
                            setSort(event.target.value)
                        }} inputProps={{
                            name: 'age',
                            id: 'outlined-age-native-simple',
                        }}
                    >
                        {Array.from(sortFunctionsMap.keys()).map(key =>
                            <option key={key} value={key}>{key}</option>
                        )}
                    </Select>
                </FormControl>
            </Box>
        </React.Fragment >
    )

    const metadata = CatalogPageMetadata().withComponent(
        () => {
            return (

                <Grid container>
                    <Grid item
                        xs={12}
                        md={3}
                        className="catalog_left_hand"
                    >
                        {isMobileDevice ?
                            <SimpleModal openButtonName={"Filter"} isOpen={isOpen} onClick={setIsOpen}>
                                <LeftHandFilters platform="mobile" />
                            </SimpleModal> :
                            <Box className="leftHandMaster">
                                <LeftHandFilters platform="desktop" />
                                <Box className="contactBoxDesktop">
                                    <Box className="readyforEVTitle">
                                        <EmailIcon />
                                        <Typography className="contactText">Ready for EVs?</Typography>
                                    </Box>
                                    <ContactFormModal />
                                </Box>
                            </Box>
                        }

                    </Grid>
                    <Grid item
                        xs={12}
                        md={9}
                        className="catalog_right_hand"
                    >
                        <React.Fragment>
                            {error ? (
                                <>Oh no, there was an error</>
                            ) : isUninitialized ? (
                                <></>
                            ) : isLoading ? (
                                <React.Fragment>
                                    <center>...</center>
                                </React.Fragment>
                            ) : data ? (
                                <React.Fragment>
                                    {catalogSort}
                                    <Catalog
                                        indexes={
                                            filterValues(data.vehicles, vehicleFilterFieldNames, vehicleFilterFieldNameToValueMapping)
                                        }
                                        data={data.vehicles}
                                        sortFunction={sortFunctionsMap.get(sort)}
                                        cardRender={vehicleCardRenderer}
                                        containerProps={{ container: true, spacing: 3 }}
                                        additionalClassNames={"vehicle_card_container"}
                                    />
                                    {isMobileDevice ?
                                        <Box className="contactBoxMobile">
                                            <Box className="contactBoxTitleMobile">
                                                <EmailIcon />
                                                <Typography className="contactBoxMobileText">Ready to reach out?</Typography>
                                            </Box>
                                            <ContactFormModal />
                                        </Box> : null}
                                </React.Fragment>
                            ) : null}
                        </React.Fragment>

                    </Grid>
                </Grid >




            )
        }
    ).build()


    return (
        <React.Fragment>
            <GenericMetadataParser className="vehicle_catalog" componentMetadata={metadata} />
        </React.Fragment>
    )
}

export default VehicleCatalogPage;

