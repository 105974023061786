import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';

import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const MerchantsVehicleCatalogCard = () => (new _MerchantsVehicleCatalogCard());
class _MerchantsVehicleCatalogCard {

    constructor() {
        this.inputs = {
            cardMediaTitle: "",
            title: "",
            subType: "",
            imageUrl: "",
            electricRange: 0,
            vehicleType: "",
            totalRange: 0,
            batteryCapacity: 0,
            vehicleHandle: "",
            msrp: 0,
            // onClick: () => {},
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `is${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        return (
            <NavLink
                to={`vehicle_details/${this.inputs.vehicleHandle}`}>
                <Card className="vehicle_card">
                    <CardHeader
                        title={this.inputs.title}
                        subheader={this.inputs.subType}
                        className="vehicle_card_header"
                    />
                    <CardMedia
                        className="vehicle_card_media"
                        component="img"
                        src={this.inputs.imageUrl}
                        title={this.cardMediaTitle}
                    />
                    <CardContent>
                        <Box className="vehice_card_details">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="card_detail_title">
                                            Vehicle Type
                                        </td>
                                        <td className="card_detail">
                                            {this.inputs.vehicleType}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="card_detail_title">
                                            Electric Range
                                        </td>
                                        <td className="card_detail">
                                            {this.inputs.electricRange} miles
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="card_detail_title">
                                            Total Range
                                        </td>
                                        <td className="card_detail">
                                            {this.inputs.totalRange} miles
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="card_detail_title">
                                            Battery Capacity
                                        </td>
                                        <td className="card_detail">
                                            {this.inputs.batteryCapacity} kWh
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="card_detail_title">
                                            MSRP
                                        </td>
                                        <td className="card_detail">
                                            ${this.inputs.msrp}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                    </CardContent>
                </Card>
            </NavLink>
        )
    }
}

export default MerchantsVehicleCatalogCard;




