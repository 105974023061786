import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as TooltipIcon } from '../../assets/icons/tooltip.svg'


const TriggersTooltips = ({tooltipMetadata}) => {

    return (
        <Tooltip disableFocusListener title={tooltipMetadata}>
            <TooltipIcon />
        </Tooltip>

    );
}
export default TriggersTooltips