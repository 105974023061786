
import { useState } from 'react';

import RenderPrintForm, { PrintModalMetadataBuilder } from "./PrintModalForm";

import { Modal } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { useSelector } from 'react-redux';

import { retrievers } from '../../../slices/IncentivePageNavigationSlice';


const SimpleModal = (props) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <div className="printSection">
            <div className="printButton" type="button" onClick={handleOpen}>
                <PrintIcon />
                <p className="printTitle">Print</p>
            </div>
            <Modal
                ref={props.ref}
                open={open}
                onClose={handleClose}
                aria-labelledby="print-modal-title"
                aria-describedby="print-modal-description"
                id={props.id}
            >
                {props.children}
            </Modal>
        </div>
    )
}

let tabPanels = ["Overview", "Eligibility", "Funding", "Changelog"]

function print(currentTab) {
    if (currentTab === null) {
        document.getElementById('print_details_page').style.display='block';
        document.getElementById('IncentiveDetails').style.display = 'none';
    } 
    document.getElementById('tabsDisplay').style.display='none'
    
    window.print();

    // reset after print
    document.getElementById('tabsDisplay').style.display = 'block'
    document.getElementById('print_details_page').style.display = 'none';
    document.getElementById('IncentiveDetails').style.display = '';

}

const PrintModal = () => {
    const selectedTab = useSelector(retrievers.currentTab);
    
    return (
        <SimpleModal id="printModal">
                <RenderPrintForm name="print" className="printModal" componentMetadata={
                    PrintModalMetadataBuilder()
                        .withOnClickFunction((value) => { print(value)})
                        .withCurrentTabNumber(selectedTab)
                        .withCurrentTabName(tabPanels[selectedTab])
                        .build()}
                    />
        </SimpleModal>
    )
}
export default PrintModal