

export function compareOrderedArraysOfInts(array1, array2) {

    //because they are ordered
    const outPutArray = []
    let i = 0, j = 0;
    while (array1.length > i && array2.length > j) {
        // console.group("Itterate")
        // console.info("i = ",i," j = ",j)
        // console.info("val 1 = ",array1[i]," val 2 = ",array2[j]  )
        // console.info("===")
        // console.log(array1[i] === array2[j])
        if (array1[i] === array2[j]) {
            outPutArray.push(array1[i]);
            i++;
            j++;
        } else if (array1[i] < array2[j]) {
            i++;
        }
        else {
            j++;
        }
        console.groupEnd("Itterate")

    }
    return outPutArray;
}

/**************************************************
 *  
 *      CreateIndexValueKeys    
 *  
 * *************************************************/
export const createIndexValueKeys = (
    catalog,
    keysOfInterest
) => {
    const valueKeys = new Map();
    catalog.forEach((item, index) => {
        keysOfInterest.forEach((key) => {
            
            if (!valueKeys.has(key)) {
                valueKeys.set(key, new Map());
            }
            let vk = valueKeys.get(key)
            vk.set("all", [...Array(catalog.length).keys()])
            if (Array.isArray(item[key])) {
                (item[key]).forEach((val) => {
                    if (!vk.has(val)) {
                        vk.set(val, []);
                    }
                    (vk.get(val)).push(index)
                })
            } else {
                if (!vk.has(item[key])) {
                    vk.set(item[key], []);
                }
                vk.get(item[key]).push(index);
            }
        })
    })
    return valueKeys;
}
/**
 * Returns a Boolean value that indicates whether a value is a non-null-object (NnO).
 * 
 * @param {*} value 
 * @returns boolean
 */
export const isNnO = (value) => {
    return typeof value === 'object' && value !== null
}

/**
 * Returns a primitive value from a string.
 * 
 * @param {String} value 
 * @returns primitive value or {undifined}
 */

export const parseNonObjectValue = (value) => {
    return (typeof value === 'boolean') ? value :
        isNaN(value) ? value :
            (Number.isInteger(value) ? parseInt(value) :
                parseFloat(value))
}


/**
 * Returns  index values for items in an array that match the filtered criteria
 * 
 * @param {Array} data 
 * @param {Array} fieldNames 
 * @param {Array} fieldNamesToValueMapping  
 * @returns {Array} indexes of elements that match the criteria 
 */
export function filterValues(data, fieldNames, fieldNamesToValueMapping) {
    if (data.length === 0) {
        return data;
    }

    const indexValueKeys = createIndexValueKeys(data, fieldNames); 
    return fieldNames.map(
        (key, index) => {
            let val;
            const valueOfIndex = fieldNamesToValueMapping[index];
            if (isNnO(valueOfIndex)) {
                val = []
                if ('lessThan' in valueOfIndex) {
                    for (const [iKey, value] of indexValueKeys.get(key)) {
                        if (iKey < valueOfIndex.lessThan && iKey > valueOfIndex.greaterThan)
                            val.push(...value)
                    }
                }
                if (Array.isArray(valueOfIndex)) {
                    if (valueOfIndex.length === 0) {
                        return [...Array(data.length).keys()]
                    }
                    valueOfIndex.forEach((item) => {
                        if (indexValueKeys.get(key).has(parseNonObjectValue(item))) {
                            val.push(...indexValueKeys.get(key).get(parseNonObjectValue(item)))
                        }
                    }
                    )
                }
                return val.sort((a, b) => a - b)
            }
            val = indexValueKeys.get(key).get(parseNonObjectValue(valueOfIndex))
            if (typeof val === 'undefined') {
               return []
            } else return val
        }).reduce(compareOrderedArraysOfInts)
}


/**
 * Returns void, checks to see if the set has the value, if it does it will remove the value if not it will add the value
 * @param {Set} 
 * @param {value}
 * 
 * @returns void 
 */


export function updateSet(set, value) {
    if (set.has(value)) set.delete(value)
    else set.add(value)
}

