import { Grid, Typography } from '@material-ui/core';

const IncentiveOverviewTab = () => (new _IncentiveOverviewTab());
class _IncentiveOverviewTab {
    constructor() {
        this.inputs = {
            incentiveStatus: "",
            incentiveUrl: "",
            incentiveName: "",
            incentiveDescription: "",
            incentiveScope: "N/A",
            startDate: "",
            expirationDate: "",
            DateFunction: () => {},
            grantorName: "",
            grantorType: "",
            grantorInfo: "",
            // onClick: () => {},
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `is${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        let date_info = this.inputs.DateFunction(this.inputs.startDate, this.inputs.expirationDate, this.inputs.incentiveStatus)
        return ({
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: "OverViewTabPage",
                        className: "OverViewTabPage",
                        container: true,
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                xs: 12,
                                item: true,
                                id: "OverviewTabTitle",
                                container: true
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "GENERAL INFORMATION",
                                        className: "mainTitle"
                                    }
                                }
                            ]
                        },{
                            uiComponent: Grid,
                            props: {
                                id: "OverviewTabLeft",
                                container: true
                            }, 
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Incentive Name",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.incentiveName ? [this.inputs.incentiveName, <a className="detailsLink" href={this.inputs.incentiveUrl} target="_blank" rel="noreferrer">See Details</a>] : "N/A",
                                        className: "mainText"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Incentive Description",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.incentiveDescription ? this.inputs.incentiveDescription : "N/A",
                                        className: "mainText"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Incentive Scope",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.incentiveScope ? this.inputs.incentiveScope : "N/A",
                                        className: "mainText"
                                    }
                                },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "OverviewTabRight",
                                item: true,
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Application Window",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: date_info[1] === 0 && date_info[3] === "" ? "Closed" : date_info[4] === "" ? "Open - no deadline" : date_info[3] === "" ? "Closes " + date_info[4] : date_info[3] + " - " + date_info[4],
                                        className: "mainText"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Grantor Name",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.grantorName ? this.inputs.grantorName : "N/A",
                                        className: "mainText"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Grantor Type",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.grantorType ? this.inputs.grantorType : "N/A",
                                        className: "mainText"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: "Grantor Contact Info",
                                        className: "mainsubTitle"
                                    }
                                },
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                    },
                                    uiComponent: Typography,
                                    props: {
                                        children: this.inputs.grantorInfo ? this.inputs.grantorInfo : "N/A",
                                        className: "mainText"
                                    }
                                },
                            ]
                        },
                    ]
                }
            ]
        })
    }
}



export default IncentiveOverviewTab;
