import React from 'react'
import VehicleDetailsMetadata from '../../ComponentMetadata/VehicleDetailsMetadata'
import GenericMetadataParser from '../GenericMetadataParser'
import { useGetCommercialVehicleQuery } from '../../services/unifiedAPIQuery';
import { useParams } from 'react-router';

const VehicleDetailsPage = () => {
    const { handle, postcode } = useParams()

    const { data, error, isLoading, isUninitialized } = useGetCommercialVehicleQuery(
        { handle: handle, postcode: postcode },
    )

    return (
        <React.Fragment>
            {error ? (
                <>Oh no, there was an error</>
            ) : isUninitialized ? (
                <></>
            ) : isLoading ? (
                <React.Fragment>
                    <center>...</center>
                </React.Fragment>
            ) : data ? (
                <GenericMetadataParser className="vehicle_details_page" componentMetadata={VehicleDetailsMetadata
                    .withVehicleHeroImg(data.vehicle.images[0].url_full)
                    .withVehicleTitle(`${data.vehicle.make} ${data.vehicle.display_name}`) 
                    .withMsrp(data.vehicle.msrp)
                    .withVehicleType(data.vehicle.form_factor)
                    .withWeightClass(data.vehicle.weight_class) 
                    .withSubtype(data.vehicle.subtype)
                    .withGvwr('boom')
                    .withCargoSpace('boom') 
                    .withElectricRange(data.vehicle.electric_range)
                    .withPayloads(data.vehicle.payload)
                    .withTotalRange(data.vehicle.total_range)
                    .withBatteryCapacity(data.vehicle.battery_capacity)
                    .withPlugFormat('boom')
                    .withPowertrain(data.vehicle.drivetrain)
                    .withBatteryType(data.vehicle.battery_type)
                    .withMaxChargeRate("boom") 
                    .build()} />
            ) : null}
        </React.Fragment>
    )
}



export default VehicleDetailsPage