import { Grid, Typography } from "@material-ui/core";




const CatalogPageMetadata = () => (new _CatalogPageMetadata());

export class _CatalogPageMetadata {

    constructor() {
        this.inputs = {
            filters: {},
            component: {},
            pageId:"page",
            pageClassName:"page_class",
            pageTitle: "",
            pageSubTitle:"",
            disclaimer: {}
        }

        //dynamically generating setters for inputs in constructor
        Object.keys(this.inputs).forEach(input => {
            // if booleanValue, it will have the property name became has
            let functionNameValue = (typeof this.inputs[input] == "boolean") ?
                `is${input.charAt(0).toUpperCase() + input.slice(1)}` :
                `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

            this[`${functionNameValue}`] = (value) => {
                this.inputs[`${input}`] = value;
                return this
            }
        })
    }

    build() {
        return ({
            sections: [
                {
                    uiComponent: Grid,
                    props: {
                        id: this.inputs.pageId,
                        className: this.inputs.pageClassName,
                        container: true,
                    },
                    sections: [
                        {
                            uiComponent: Grid,
                            props: {
                                id: "PageTitle",
                                container: true,
                            },
                            elements: [{
                                gridItemProps: {
                                    item: true,
                                    xs: 12,
                                },
                                uiComponent: Typography,
                                props: {
                                    className: "page_title",
                                    children: this.inputs.pageTitle,
                                },
                            },
                            {
                                gridItemProps: {
                                    item: true,
                                    xs: 12,
                                },
                                uiComponent: Typography,
                                props: {
                                    className: "page_sub_title",
                                    children: this.inputs.pageSubTitle,
                                },
                            }
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "CatalogDropdownFilters",
                                container: true,
                            },
                            elements: [{
                                gridItemProps: {
                                    item: true,
                                    xs: 12,
                                },
                                uiComponent: this.inputs.filters,
                            },
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "PageComponent",
                                className: "page_component",
                                container: true,
                            },
                            elements: [
                                {
                                    gridItemProps: {
                                        item: true,
                                        xs: 12,
                                         className: "incentive_table"
                                    },
                                    uiComponent: this.inputs.component
                                }
                            ]
                        },
                        {
                            uiComponent: Grid,
                            props: {
                                id: "PageDisclaimer",
                                container: true,
                            },
                            elements: [{
                                gridItemProps: {
                                    item: true,
                                    xs: 12,
                                },
                                uiComponent: Typography,
                                props: {
                                    className: "page_disclaimer",
                                    children: this.inputs.disclaimer,
                                },
                            },
                            ]
                        },
                    ]
                }
            ]
        })
    }
}

export default CatalogPageMetadata;